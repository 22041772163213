import React, { useState } from "react";
import Content from "../../../layout/content/Content";
import LogoLG from "../../../layout/logo/Logo-LG";
import Head from "../../../layout/head/Head";
import AuthFooter from "../../auth/AuthFooter";

import { Block, BlockHead, Button, Loading, PreviewCard, VoiceRecorder } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { Alert, Col, Row, Spinner } from "reactstrap";
import { IoMicCircleSharp, IoPlayCircleSharp, IoStopCircle } from "react-icons/io5";

const VoiceRecognition = ({ ...props }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const request_id = queryParameters.get("request_id");
  const [loading, setLoading] = useState(true);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [error, setError] = useState();
  const [formData, setFormData] = useState({
    audio1: "",
  });
  const [recording, setRecording] = useState(false);
  const [recordedMessage, setRecordedMessage] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [playbackTime, setPlaybackTime] = useState(0); // New state for playback time
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioURL, setAudioURL] = useState(null);
  const [audio, setAudio] = useState(null);
  const [audioDuration, setAudioDuration] = useState(0);
  const [sentence, setSentence] = useState();

  const sentences = [
    "The quick brown fox jumps over the lazy dog.",
    "She sells seashells by the seashore.",
    "How much wood would a woodchuck chuck if a woodchuck could chuck wood?",
    "Peter Piper picked a peck of pickled peppers.",
    "I scream, you scream, we all scream for ice cream.",
    "Red lorry, yellow lorry.",
    "Betty Botter bought some butter.",
    "Can you can a can as a canner can can a can?",
    "Fred fed Ted bread, and Ted fed Fred bread.",
    "How can a clam cram in a clean cream can?"
  ];

  const generateRandomSentence = () => {
    const randomIndex = Math.floor(Math.random() * sentences.length);
    setSentence(sentences[randomIndex]);
  };


  const buttonSize = 130

  useEffect(() => {
    generateRandomSentence()
    let timer;
    if (recording) {
      timer = setInterval(() => {
        setElapsedTime(prevTime => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [recording]);

  const formatTime = (seconds) => {
    const roundedSeconds = Math.floor(seconds); // Round to the nearest second
    const minutes = Math.floor(roundedSeconds / 60);
    const remainingSeconds = roundedSeconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };



  // const [request, setRequest] = useState(JSON.parse(localStorage.getItem("at_requestor")))
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
  } = useForm();

  useEffect(() => {
    getUserInfo();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const getUserInfo = async () => {
    await apiRequest("/ccr/kyc/" + request_id, "GET").then((resp) => {
      if (resp.code === 200) {
        console.log(resp);
        setValue("from_name", resp.data.request.from_name);
        setValue("from_company", resp.data.request.from_company);
        setLoading(false);
      }
      console.log(getValues());
    });
  };

  const handleStartRecording = async () => {
    try {


      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      setRecording(true);
      setRecordedMessage(false);
      setElapsedTime(0); // Reset timer when starting new recording
      const audioChunks = [];
      recorder.ondataavailable = event => {
        audioChunks.push(event.data);
      };

      recorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
        console.log("Audio Blob:", audioBlob); // Debugging: log the audio blob
        setFormData({ ...formData, audio1: audioBlob });
        const audioUrl = URL.createObjectURL(audioBlob);
        console.log("Audio URL:", audioUrl); // Debugging: log the audio URL
        setAudioURL(audioBlob);
        const newAudio = new Audio(audioUrl);
        newAudio.onloadedmetadata = () => {
          setAudioDuration(newAudio.duration);
          console.log("Audio Duration:", newAudio.duration); // Debugging: log the audio duration
        };
        setAudio(newAudio); // Create Audio object for playback
      };

      recorder.start();
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };


  const handleStopRecording = () => {
    setRecording(false);
    setRecordedMessage(true);
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
  };

  const handlePlayRecording = () => {
    if (audio) {
      setPlaying(true);
      setPlaybackTime(0); // Reset playback time

      const playbackTimer = setInterval(() => {
        setPlaybackTime(audio.currentTime);
      }, 1000);

      audio.play().then(() => {
        console.log("Playback started successfully");
      }).catch(error => {
        console.error("Error playing audio:", error);
        setPlaying(false);
        clearInterval(playbackTimer);
      });

      audio.onended = () => {
        setPlaying(false);
        clearInterval(playbackTimer);
      };
    } else {
      console.error("Audio object is not set.");
    }
  };

  const handleStopPlayback = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      setPlaying(false);
      setPlaybackTime(0); // Reset playback time
    }
  };




  const [load, setLoad] = useState(0);
  if (load < 1) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoad(2);
  }



  const locationAccess = async () => {
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true, // Request high accuracy (GPS)
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0, // Ensure the result is not a cached position
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setValue("location", { latitude, longitude });
          resolve({ latitude, longitude });
        },
        (err) => {
          // Handle error
          console.log(err);
          resolve({ latitude: null, longitude: null });
        },
        options,
      );
    });
  };

  const onFormSubmit = async () => {
    setSubmitButtonLoading(true);
    console.log(formData.audio1);
    setError();
    if (!formData.audio1) {
      setError("Please Record your voice");
      setSubmitButtonLoading(false);
      return;
    }
    console.log(formData);
    var file = new File([formData.audio1], "audio1.ogg", {
      type: formData.audio1.type,
    });
    console.log(file);
    var data = new FormData();

    data.append("biometric_voice", formData.audio1);
    const location = await locationAccess();
    data.append("latitude", location.latitude || null);
    data.append("longitude", location.longitude || null);
    console.log(data);
    try {
      await apiRequest(`/ccr/kyc/step/7`, "POST", data, "file").then((resp) => {
        resp = JSON.parse(resp);
        console.log(resp);
        if (resp.code === 200) {
          console.log(resp);
          props.jump(resp.data.step);
        } else {
          setSubmitButtonLoading(false);
          console.log(resp.message);
        }
      });
    } catch (err) {
      setSubmitButtonLoading(false);
      console.log(err);
    }
  };
  // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  return (
    <React.Fragment>
      <Head title="Voice Recording" />
      {/* <HeaderCCR></HeaderCCR> */}
      <div className="mb-2"></div>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <div className="content-page wide-md m-auto">
            <Block className={submitButtonLoading && "disabled"}>
              <h5>Voice Recording</h5>
              <p>Start recording your voice reading the sentence that appears.</p>
              <form className="content clearfix" onSubmit={handleSubmit(onFormSubmit)}>
                <div className="gy-4 row">
                  <div className="entry">
                    <h6>To avoid delays in verifying you, please follow the instructions below:</h6>
                    <ul className="list list-sm list-checked">
                      <li>Your browser has permissions to access your microphone.</li>
                      <li>There is no background noise.</li>
                      <li>You read the sentence in your natural voice.</li>
                    </ul>
                    <h6>Please click the microphone to start recording your voice.</h6>
                    <Block>
                      {recording && <Alert>
                        <div>Please read this:</div>
                        {sentence}
                      </Alert>}

                      <Block className="d-flex justify-content-center align-items-center mb-4">
                        <Row>
                          {!recording && !playing && recordedMessage && (
                            <Col className="text-center">
                              <IoPlayCircleSharp
                                size={buttonSize}
                                color='blue'
                                onClick={handlePlayRecording}
                              />
                              <div className="font-weight-bold h4">Playback</div>
                            </Col>
                          )}
                          {!recording && !playing && (
                            <Col className="text-center">
                              <IoMicCircleSharp
                                onClick={handleStartRecording}
                                size={buttonSize}
                                color='red'
                              />
                              <div className="font-weight-bold h4">{recordedMessage ? 'Record Again' : 'Record'}</div>
                            </Col>
                          )}
                          {recording && !playing && (
                            <Col className="text-center">
                              <IoStopCircle
                                onClick={handleStopRecording}
                                size={buttonSize}
                                color="red"
                              />
                            </Col>
                          )}

                          {playing && (
                            <Col className="text-center">
                              <IoStopCircle
                                size={buttonSize}
                                color="red"
                                onClick={handleStopPlayback}
                              />
                            </Col>
                          )}
                        </Row>

                      </Block>
                      <Block>

                        <div className="text-center">
                          {recording && !playing && (
                            <div className="font-weight-bold h4">Recording Time: {formatTime(elapsedTime)}</div>
                          )}
                          {playing && (
                            <div className="font-weight-bold h4">Playback Time: {formatTime(playbackTime)}</div>
                          )}
                        </div>

                      </Block>

                    </Block>
                    {error && <div className="text-danger h3">{error}</div>}
                    <div className="mt-3 nk-kycfm-note mt-5">
                      <em className=" icon ni ni-info-fill"></em>
                      {/* <p>
                      Your voice recording is encrypted, analyzed, authenticated
                      and only used to verify you. It is only shared withand his
                      organization, who alone control access under encryption.
                      The voice recording is stored securely at our CA1 Region
                      Datacenter [Toronto, Canada].
                    </p> */}
                      <p>
                        Your voice recording is encrypted, analyzed, authenticated and only used to verify you by{" "}
                        <b>{getValues("from_name")}</b> and <b>{getValues("from_company")}</b>.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="actions clearfix">
                    <ul>
                      <li>
                        <button type="submit" style={{float:'right'}} className="btn btn-primary">Next</button>
                      </li>
                    </ul>
                  </div> */}
                <div className="bg-primary" style={{ position: "fixed", bottom: "0px" }}>
                  <div className="bg-white col-12" style={{ position: "fixed", bottom: "0px", right: "0px" }}>
                    <div style={{ margin: "10px 5px 10px 0px", float: "right" }} className="p-1">
                      {" "}
                      <Button color="primary" size="" className={submitButtonLoading && "disabled"} outline="">
                        {submitButtonLoading && <Spinner size="sm" />} Continue
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </Block>
          </div>
        </Content>
      )}
    </React.Fragment>
  );
};

export default VoiceRecognition;
