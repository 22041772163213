import React from "react";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable'
import { Icon } from "../Component";


const RSelect2 = ({ ...props }) => {
  // console.log(props.options)
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 38,
      minHeight: 35,
    }),
  };

  const handleBottomArrive = () => {
    console.log("Bottom reached!");
  };

  return (
    <div>
      {props.label && (
        props.sub ? <div className="form-label">
          <label className="form-label" htmlFor="default-01">
            {props.label}
            {props.requiredMessage && <span className="text-danger">*</span>}
          </label>
          {props.sub && <span className="form-note">{props.sub}</span>}
          {props.icon && <Icon style={props.iconStyle} name={props.icon}></Icon>}
          {/* {props.errors && props.errors[props.name] && <p className="invalid">{props.errors[props.name].message}</p>} */}
        </div>
          :
          <div className="form-label-group">
            <label className="form-label" htmlFor="default-01">
              {props.label}
              {props.requiredMessage && <span className="text-danger">*</span>}
            </label>
            {props.sub && <span className="form-note">{props.sub}</span>}
            {props.icon && <Icon style={props.iconStyle} name={props.icon}></Icon>}
            {/* {props.errors && props.errors[props.name] && <p className="invalid">{props.errors[props.name].message}</p>} */}
          </div>

      )}
      <div className="form-control-select mb-2">
        {props.createable ?
          <CreatableSelect
            isDisabled={props.disabled}
            captureMenuScroll={true}
            // onBottomArrive={handleBottomArrive}
            {...props.register(props.name, { required: props.requiredMessage })}
            className={`react-select-container  ${props.className ? props.className : ""}`}
            classNamePrefix="react-select"
            defaultValue={props.defaultValue && props.options[props.defaultValue]}
            style={{ height: "38px", minHeight: "35px" }}
            onChange={(e, name) => {
              if (Array.isArray(e)) {
                const data = [];
                for (let i = 0; i < e.length; i++) {
                  data.push(e[i].value);
                }
                props.setValue(props.name, data);
                props.onselect(name.name, data);
              } else {
                props.setValue(props.name, e.value);
                props.onselect(name.name, e.value);
              }
            }}

            onselect={props.onselect}
            // onMenuScrollToBottom={handleBottomArrive}
            {...props}
          /> :
          <Select
            isDisabled={props.disabled}
            {...props.register(props.name, { required: props.requiredMessage })}
            className={`react-select-container  ${props.className ? props.className : ""}`}
            classNamePrefix="react-select"
            defaultValue={props.defaultValue && props.options[props.defaultValue]}
            style={{ height: "38px", minHeight: "35px" }}
            onChange={(e, name) => {
              if (Array.isArray(e)) {
                const data = [];
                for (let i = 0; i < e.length; i++) {
                  data.push(e[i].value);
                }
                props.setValue(props.name, data);
                props.onselect(name.name, data);
              } else {
                props.setValue(props.name, e.value);
                props.onselect(name.name, e.value);
              }
            }}
            onBottomArrive={handleBottomArrive}
            onselect={props.onselect}
            isSearchable={props.isSearchable || false}
            // onMenuScrollToBottom={true}
            {...props}
          />}
        {props.errors && props.errors[props.name] && <p className="invalid">{props.errors[props.name].message}</p>}
      </div>
    </div>
  );
};

export default RSelect2;
