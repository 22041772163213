import * as Sentry from "@sentry/react";
import { removeToken } from "./Utils";

export function apiRequest(uri = "", method = "GET", _data = false, dataType = "application/json", async = true) {
  let options = {
    method: method,
    headers: {
      "Content-Type": dataType,
    },
    credentials: "include", // Needed to include cookies
  };

  if (_data && dataType === "file") {
    options = {
      method: method,
      body: _data,
      credentials: "include", // Needed to include cookies
    };
  } else if (_data) {
    options.body = JSON.stringify(_data);
  }

  let proxy = "";

  if (window.location.host === "secure.athenty.com") {
    proxy = "https://api.athenty.com/v1";
    if (uri === "/customer/login") proxy = "/api/v1";
  } else if (window.location.host === "192.168.1.35:8581") {
    proxy = "http://192.168.1.35:8582/v1";
  }

  return fetch(proxy + uri, options)
    .then(async (resp) => {
      console.log(resp)

      if (resp.status === 409) {
        await removeToken()
        window.location.href = '/'
        return
      }
      if (resp.status === 500) {
        throw new Error(`HTTP error! status: ${resp.status}`);
      }
      if (dataType === "application/json" && async) {
        return resp.json();
      } else {
        return resp.text();
      }
    })
    .then((data) => {
      // Further processing of data if needed
      return data;
    })
    .catch((error) => {
      // Capture the detailed error message
      const errorMessage = `Proxy error: Could not proxy request ${uri} from ${window.location.host} to ${proxy + uri
        }. ${error.message}`;
      Sentry.captureException(new Error(errorMessage));
      return Sentry.flush(2000) // Wait for up to 2 seconds for Sentry to send the events
        .then(() => {
          // Redirect to another page after Sentry logs the error
          window.location.href = "/errors/503-modern"; // Change this to the desired URL
        })
        .catch(() => {
          // If Sentry.flush fails, still redirect
          window.location.href = "/errors/503-modern"; // Change this to the desired URL
        });
    });
}
