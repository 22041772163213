import React, { useEffect, useMemo, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import countryList from "react-select-country-list";
import { regions } from "../../data/country";
import { Block, Input, Button, Textarea, Loading, InputCheckbox } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import { useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import RSelect2 from "../../../components/select/ReactSelect2";
const KycLocation = ({ ...props }) => {
  // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [loading, setLoading] = useState();
  const [searchValues, setSearchValue] = useState()
  const [predictions, setPredictions] = useState()
  const [searchString, setSearchString] = useState()
  const [location, setLocation] = useState()
  const [selectedAddress, setSelectedAddress] = useState()

  const y_n_options = [
    { value: "y", label: "Yes" },
    { value: "n", label: "No" },
  ];

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
    setError
  } = useForm();

  useEffect(() => {
    getLocation()
    console.log('useeffect')
    setLoading(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // setValue('request_id',JSON.parse(localStorage.getItem("at_requestor")).id)
  }, []);

  const getLocation = async () => {
    const location = await locationAccess();
    setLocation(location)
  }
  // const [load, setLoad] = useState(0);
  // if (load < 1) {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   setLoad(2);
  // }

  const onselect = (name, value) => {
    console.log('on select')
  };

  const searchAddress = async (e) => {
    console.log(e.target.value.length)
    if (!location) return
    if (!e.target.value || e.target.value.length < 3) {
      console.log('break')
      setPredictions()
      return
    }
    console.log(location)
    setSearchString(e.target.value)
    const cords = { lat: location.latitude, lng: location.longitude }
    await apiRequest(`/ccr/verify/addressLookup`, "POST", { address: e.target.value, cords }).then(resp => {
      setPredictions(resp.data.predictions)
    })
  }

  const locationAccess = async () => {
    setLoading(true)
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true, // Request high accuracy (GPS)
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0, // Ensure the result is not a cached position
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setValue("location", { latitude, longitude });
          resolve({ latitude, longitude });
          setLoading(false)
        },
        (err) => {
          // Handle error
          console.log(err);
          resolve({ latitude: null, longitude: null });
          setLoading(false)
        },
        options,
      );
    });
  };

  const onFormSubmit = async (e) => {
    // console.log("form submit");
    // console.log(getValues())
    setSubmitButtonLoading(true)
    const location = await locationAccess();
    setValue("latitude", location.latitude);
    setValue("longitude", location.longitude);
    console.log(getValues())
    try {
      await apiRequest("/ccr/verify/step/2", "POST", getValues()).then((resp) => {
        if (resp.code === 200) {
          // console.log(resp);
          props.jump(resp.data.step);
        } else {
          console.log("error response: ", resp.message);
          setSubmitButtonLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
      setSubmitButtonLoading(false);
    }
  };


  const handleFormSubmit = async (event) => {
    event.preventDefault()
    console.log(getValues('address'))
    console.log(typeof getValues('address'))
    console.log(event)

    event.preventDefault();
    console.log('missing');

    await handleSubmit(onFormSubmit)();
    if (Object.keys(errors).length) {
      toast.error('missing information', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleChange = (selectedOption) => {
    console.log("Selected address:", selectedOption);
    // Add logic for what to do with the selected address
  };

  const setAddress = (location) => {
    setValue('address', location)
  }



  return (
    <React.Fragment>
      <Head title="Your Location" />
      {/* <HeaderCCR></HeaderCCR> */}
      <div className="mb-2"></div>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <div className="content-page wide-md m-auto">
            <Block className={submitButtonLoading && "disabled"}>
              <h5>Your Location</h5>
              {/* <p>Please search and select your <b>current address</b> (the address you currently live at) using the search field below.</p> */}
              <p>Please search and select your <b>current address</b> (the address you currently live at) using the search field below.</p>

              <form className="content clearfix" onSubmit={handleFormSubmit}>
                <div className="gy-4 row">
                  <div className="entry">
                    <Row>
                      <div style={{ paddingLeft: "45px", paddingBottom: "10px" }} className="custom-control-group pt-2">
                        <div className="custom-control custom-checkbox">
                          <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="cantFind"
                                checked={watch("cantFind")}
                                {...register("cantFind")}
                                onChange={() => {
                                  setPredictions(null); setValue('address', null); setValue("cantFind", !getValues("cantFind"));
                                }}
                              />

                              <label className="custom-control-label" htmlFor="cantFind">
                                <div>Can't find your address?</div>
                              </label>
                              {errors.cantFind && <p className="invalid">{errors.cantFind.message}</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                    {!getValues("cantFind") && <Row className="">
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <label className="form-label" htmlFor="name">
                          Search Address*
                        </label>

                        <input
                          autoComplete="off"
                          type="text"
                          id='address'
                          value={watch('address') ? watch('address').description : ''}
                          onInputCapture={(e) => searchAddress(e)}
                          {...register('address', 'This field is required')}
                          // onChange={(e) => type === 'file' && onChange ? onChange(name, e.target.files[0]) : onchange}
                          placeholder={'Search'}
                          className={"form-control"}
                        />


                      </Col>
                    </Row>}
                    {!getValues("cantFind") && predictions && searchString.length >= 3 && (!getValues('address') || typeof getValues('address') !== 'object') && < Row >
                      <ul>
                        {predictions.map((location) => {
                          return (
                            <li className={"form-control"} onClick={() => setAddress(location)}>{location.description}</li>
                          )
                        })}
                      </ul>
                    </Row>
                    }

                    {getValues("cantFind") && <Row>
                      <Row className="">
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <Input
                            type="text"
                            label="Address Line 1"
                            id="legal_address1"
                            className="form-control-lg form-control"
                            name="legal_address1"
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,20}$/,
                                message: "Max 20 characters",
                              },
                            }}
                            errors={errors}
                            register={register}
                          />
                        </Col>
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <Input
                            type="text"
                            label="Address Line 2"
                            id="legal_address2"
                            className="form-control-lg form-control"
                            name="legal_address2"
                            errors={errors}
                            register={register}
                            requiredMessage={{
                              pattern: {
                                value: /^.{1,20}$/,
                                message: "Max 20 characters",
                              },
                            }}
                          />
                        </Col>
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <RSelect2
                            onselect={onselect}
                            setValue={setValue}
                            defaultValue={watch("legal_country")}
                            label="Country"
                            register={register}
                            requiredMessage={{
                              pattern: {
                                value: /^.{1,20}$/,
                                message: "Max 20 characters",
                              },
                            }}
                            value={countryOptions.find(country => country.value === watch('legal_country'))}
                            id="legal_country"
                            name="legal_country"
                            errors={errors}
                            options={countryOptions}
                            placeholder="Select country"
                            className="form-control-lg p-0 border-0 form-control"
                          />
                        </Col>
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          {watch("legal_country") !== "CA" && watch("legal_country") !== "US" && (
                            <Input
                              type="text"
                              label="Province/State"
                              id="legal_region"
                              className="form-control-lg form-control"
                              name="legal_region"
                              required
                              requiredMessage={{
                                required: "This field is required",
                                pattern: {
                                  value: /^.{1,20}$/,
                                  message: "Max 20 characters",
                                },
                              }}
                              errors={errors}
                              register={register}
                              value={watch('legal_region')}
                            />
                          )}
                          {(watch("legal_country") === "CA" || watch("legal_country") === "US") && (
                            <RSelect2
                              onselect={onselect}
                              setValue={setValue}
                              selectedCountry={watch("legal_region")}
                              label="Province/State"
                              register={register}
                              requiredMessage="This field is required"
                              id="legal_region"
                              name="legal_region"
                              errors={errors}
                              options={regions[watch("legal_country")]}
                              placeholder="Select Region"
                              className="form-control-lg p-0 border-0 form-control"
                              value={regions[watch("legal_country")].find(region => region.value === watch('legal_region'))}
                            />
                          )}
                          {/* {watch("legal_country") === "CA" && (
                          <RSelect2
                            onselect={onselect}
                            setValue={setValue}
                            selectedCountry={watch("legal_region")}
                            label="Region"
                            register={register}
                            requiredMessage="This field is required"
                            id="legal_region"
                            name="legal_region"
                            errors={errors}
                            options={regions[watch("legal_country")]}
                            placeholder="Select Region"
                            className="form-control-lg p-0 border-0 form-control"
                          />
                        )} */}
                        </Col>
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <Input
                            type="text"
                            label="City"
                            id="legal_city"
                            className="form-control-lg form-control"
                            name="legal_city"
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,20}$/,
                                message: "Max 20 characters",
                              },
                            }}
                            errors={errors}
                            register={register}
                          />
                        </Col>

                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <Input
                            type="text"
                            label="Postal/Zip Code"
                            id="legal_postal"
                            className="form-control-lg form-control"
                            name="legal_postal"
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,20}$/,
                                message: "Max 20 characters",
                              },
                            }}
                            errors={errors}
                            register={register}
                          />
                        </Col>
                      </Row>

                    </Row>}
                    <div className="mt-3 mb-5 nk-kycfm-note">
                      <em className=" icon ni ni-info-fill"></em>
                      <p>
                        We use your current location to verify that it matches your address on at least one of your ID
                        documents.
                      </p>
                    </div>

                    <div className="bg-primary" style={{ position: "fixed", bottom: "0px" }}>
                      <div className="bg-white col-12" style={{ position: "fixed", bottom: "0px", right: "0px" }}>
                        <div style={{ margin: "10px 5px 10px 0px", float: "right" }} className="p-1">
                          {" "}
                          <Button disabled={!getValues('cantFind') && typeof getValues('address') !== 'object'} color="primary" size="" className="" outline="">
                            {submitButtonLoading && <Spinner size="sm" />} Continue
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="actions clearfix">
                    <ul>
                      <li>
                        <button type="submit" style={{float:'right'}} className="btn btn-primary">Next</button>
                      </li>
                    </ul>
                  </div> */}
              </form>
            </Block>
          </div>
        </Content>
      )
      }
      <ToastContainer />
    </React.Fragment >
  );
};

export default KycLocation;
