import { useEffect } from "react"
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import { removeToken } from "../../utils/Utils";


const LogOut = () => {
    const navigate = useNavigate();
    useEffect(() => {
        console.log('use')
        handleSignout()
    }, [])



    const handleSignout = async () => {
        console.log("sign out");
        await removeToken();
        navigate('/')
        // window.location.href = "/";
    };

}

export default LogOut