import React, { useRef, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import SignatureCanvas from "react-signature-canvas";
import { Block, Button, Loading } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import { useForm } from "react-hook-form";
import { Row, Spinner } from "reactstrap";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

const Attestation = ({ ...props }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const request_id = queryParameters.get("request_id");
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm();
  const [sign, setSign] = useState();
  const [error, setError] = useState();

  let signPad = useRef({});
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const [load, setLoad] = useState(0);
  if (load < 1) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoad(2);
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    await apiRequest("/ccr/kyc/" + request_id, "GET").then((resp) => {
      if (resp.code === 200) {
        console.log(resp);
        setValue("from_name", resp.data.request.from_name);
        setValue("from_company", resp.data.request.from_company);
        setLoading(false);
      }
      // console.log(getValues());
    });
  };

  const onClear = () => {
    signPad.current.clear();
    setSign();
  };

  const locationAccess = async () => {
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true, // Request high accuracy (GPS)
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0, // Ensure the result is not a cached position
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setValue("location", { latitude, longitude });
          resolve({ latitude, longitude });
        },
        (err) => {
          // Handle error
          console.log(err);
          resolve({ latitude: null, longitude: null });
        },
        options,
      );
    });
  };

  const onFormSubmit = async () => {

    setSubmitButtonLoading(true);
    const location = await locationAccess();
    setValue("latitude", location.latitude);
    setValue("longitude", location.longitude);
    const formData = {
      tos: true,
      truth: true,
      document: getValues("document"),
      personal: getValues("personal"),
      biometric: getValues("biometric"),
      third_party: getValues("third_party"),
      process: getValues("process"),
      tracking: getValues("tracking"),
      latitude: location.latitude,
      longitude: location.longitude,
      // signature:  atob(signPad.current.toDataURL().split('base64,')[1])
      signature: signPad.current.getTrimmedCanvas().toDataURL(),
    };

    setSign(formData.signature);
    console.log(typeof formData.signature);
    try {
      setLoading(true);
      await apiRequest("/ccr/kyc/step/10", "POST", formData).then((resp) => {
        if (resp.code === 200) {
          // console.log(resp);
          props.jump(resp.data.step);
        } else {
          console.log("error response: ", resp.message);
          setSubmitButtonLoading(true);
        }
      });
    } catch (err) {
      console.log(err);
      setSubmitButtonLoading(true);
    }
  };


  const consolel = (data) => {
    console.log(data);
    // Perform your form submission logic here
  };


  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // console.log('submit');
    console.log(signPad)
    if (signPad && signPad.current && signPad.current.isEmpty()) {
      setError("Please sign");
      // return;
    } else {
      setError();
    }
    if (Object.keys(errors).length) {
      toast.error('missing information', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    await handleSubmit(onFormSubmit)();
  };

  return (
    <React.Fragment>
      {/* {sign && <img src={sign} alt='test'/>} */}
      <Head title="Attestation" />
      {/* <HeaderCCR></HeaderCCR> */}
      <div className=""></div>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <div className="content-page wide-md m-auto mb-5">
            <Block className={submitButtonLoading && "disabled"}>
              <h5>Client Attestation</h5>
              <p>Please make sure all the information you have provided is correct and true.</p>
              <form className="content clearfix" onSubmit={handleFormSubmit}>
                <div className="gy-4 row pb-5">
                  <div className="entry">
                    <h6>Please confirm the following for the purposes of this matter:</h6>
                    <Row>
                      <div style={{ paddingLeft: "45px", paddingBottom: "10px" }} className="custom-control-group ">
                        <div className="custom-control custom-checkbox">
                          <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="document"
                                checked={watch("document")}
                                {...register("document", {
                                  required: "This field is required",
                                })}
                                onSelect={() => {
                                  setValue("document", !getValues("document"));
                                }}
                              />

                              <label className="custom-control-label" htmlFor="document">
                                <div>
                                  The IDs and documents provided are authentic, unexpired and valid.
                                </div>
                              </label>
                              {errors.document && <p className="invalid">{errors.document.message}</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div style={{ paddingLeft: "45px", paddingBottom: "10px" }} className="custom-control-group ">
                        <div className="custom-control custom-checkbox">
                          <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="personal"
                                checked={watch("personal")}
                                {...register("personal", {
                                  required: "This field is required",
                                })}
                                onSelect={() => {
                                  setValue("personal", !getValues("personal"));
                                }}
                              />

                              <label className="custom-control-label" htmlFor="personal">
                                <div>
                                  {/* The individual's information, their location, residency and contact information are true, accurate and current. */}
                                  The information provided, including location, residency and contact information are true, accurate and current.
                                </div>
                              </label>
                              {errors.personal && <p className="invalid">{errors.personal.message}</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div style={{ paddingLeft: "45px", paddingBottom: "10px" }} className="custom-control-group ">
                        <div className="custom-control custom-checkbox">
                          <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="biometric"
                                checked={watch("biometric")}
                                {...register("biometric", {
                                  required: "This field is required",
                                })}
                                onSelect={() => {
                                  setValue("biometric", !getValues("biometric"));
                                }}
                              />

                              <label className="custom-control-label" htmlFor="biometric">
                                {/* <div>The image and audio samples are current and of the individual's face and voice.</div> */}
                                <div>
                                  The photo of my face and recording of my voice was taken live and is real.
                                </div>
                              </label>
                              {errors.biometric && <p className="invalid">{errors.biometric.message}</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div style={{ paddingLeft: "45px", paddingBottom: "10px" }} className="custom-control-group ">
                        <div className="custom-control custom-checkbox">
                          <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="third_party"
                                checked={watch("third_party")}
                                {...register("third_party", {
                                  required: "This field is required",
                                })}
                                onSelect={() => {
                                  setValue("third_party", !getValues("third_party"));
                                  // console.log(watch("third_party"));
                                }}
                              />

                              <label className="custom-control-label" htmlFor="third_party">
                                <div>
                                  The third-party and business profile details are true, accurate and current, if applicable.
                                </div>
                              </label>
                              {errors.third_party && <p className="invalid">{errors.third_party.message}</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div style={{ paddingLeft: "45px", paddingBottom: "10px" }} className="custom-control-group ">
                        <div className="custom-control custom-checkbox">
                          <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="information"
                                checked={watch("information")}
                                {...register("information", {
                                  required: "This field is required",
                                })}
                                onSelect={() => {
                                  setValue("information", !getValues("information"));
                                }}
                              />

                              <label className="custom-control-label" htmlFor="information">
                                <div>
                                  The information provided will be analyzed to verify my identity, including my documents, to decide on the appropriateness of the Matter, and to enhance key indicators of potential flags to learn from, prevent fraud and comply with law.
                                </div>
                              </label>
                              {errors.information && <p className="invalid">{errors.information.message}</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div style={{ paddingLeft: "45px", paddingBottom: "10px" }} className="custom-control-group ">
                        <div className="custom-control custom-checkbox">
                          <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="change"
                                checked={watch("change")}
                                {...register("change", {
                                  required: "This field is required",
                                })}
                                onSelect={() => {
                                  setValue("change", !getValues("change"));
                                }}
                              />

                              <label className="custom-control-label" htmlFor="change">
                                <div>
                                  Athenty, the Reviewer and its organization will be relying on the information provided, and that if it ceases to be true, accurate or valid in any way, I shall notify the Reviewer and its organization immediately.
                                </div>
                              </label>
                              {errors.change && <p className="invalid">{errors.change.message}</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                    {/* <Row>
                      <div style={{ paddingLeft: "45px", paddingBottom: "10px" }} className="custom-control-group ">
                        <div className="custom-control custom-checkbox">
                          <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="process"
                                checked={watch("process")}
                                {...register("process", {
                                  required: "This field is required",
                                })}
                                onSelect={() => {
                                  setValue("process", !getValues("process"));
                                }}
                              />

                              <label className="custom-control-label" htmlFor="process">
                                <div>
                                  The individual consents to and understands that the information provided will be analyzed and processed to verify them, authenticate their ID documents, decide on the appropriateness of the matter, and to enhance key indicators of potential red-flags to learn from and prevent fraud. They further acknowledge that Athenty, and the requestor will be relying on the information provided and that if it ceases to be true, accurate, valid or current in any respect, they shall give immediate notice of the changes to the requestor.
                                </div>
                                <div>
                                  You consent to and understand that the information provided will be analyzed and
                                  processed to verify you, authenticate your ID documents, decide on the appropriateness
                                  of the matter, and to enhance key indicators of potential red-flags to learn from and
                                  prevent fraud. You further acknowledge that Athenty, <b>{watch("from_name")}</b>{" "}
                                  and <b>{watch("from_company")}</b> will be relying on the information provided and
                                  that if it ceases to be true, accurate, valid or current in any respect, that you
                                  shall give immediate notice of the changes to <b>{watch("from_name")}</b> and{" "}
                                  <b>{watch("from_company")}</b>.
                                </div>
                              </label>
                              {errors.process && <p className="invalid">{errors.process.message}</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row> */}
                    {/* <Row>
                      <div style={{ paddingLeft: "45px", paddingBottom: "10px" }} className="custom-control-group ">
                        <div className="custom-control custom-checkbox">
                          <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="tracking"
                                checked={watch("check7")}
                                onSelect={() => {
                                  setValue("tracking", !getValues("tracking"));
                                }}
                                {...register("tracking")}
                              />

                              <label className="custom-control-label" htmlFor="tracking">
                                <div>
                                  To follow and track the status of this verification, send me instructions to download
                                  the Athenty Protect app.
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row> */}
                    <h6 className="mt-3">Your Signature</h6>
                    <div className="p-1">
                      <Button
                        onClick={(e) => onClear(e)}
                        color="primary"
                        size=""
                        className=""
                        href="ccr/verify/complete"
                        type="button"
                        outline=""
                      >
                        Clear
                      </Button>
                    </div>
                    {error && <h3 className="text-danger">Please Sign</h3>}
                    <div className="border">
                      <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                          height: 600,
                          className: "sigCanvas",
                        }}
                        ref={signPad}
                      />
                    </div>

                    {/* <div className="mt-3 nk-kycfm-note">
                    <em className=" icon ni ni-info-fill"></em>
                    <p>You cannot edit your details once submitted.</p>
                  </div> */}
                  </div>
                </div>
                <div className="bg-primary" style={{ position: "fixed", bottom: "0px" }}>
                  <div className="bg-white col-12" style={{ position: "fixed", bottom: "0px", right: "0px" }}>
                    <div style={{ margin: "10px 5px 10px 0px", float: "right" }} className="p-1">
                      {" "}
                      <Button color="primary" size="" className="" outline="">
                        {submitButtonLoading && <Spinner size="sm" />} Submit
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <div className="actions clearfix">
                    <ul>
                      <li>
                        <button type="submit" style={{float:'right'}} className="btn btn-primary">Next</button>
                      </li>
                    </ul>
                  </div> */}
              </form>
            </Block>
          </div>
        </Content>
      )}
      <ToastContainer />
    </React.Fragment>
  );
};

export default Attestation;
