import React, { useEffect, useMemo, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import countryList from "react-select-country-list";
import { regions } from "../../data/country";
import { Block, Input, Button, Textarea, Loading, PreviewAltCard } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import { useForm } from "react-hook-form";
import RSelect2 from "../../../components/select/ReactSelect2";
import { Spinner } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";

const Location = ({ ...props }) => {
  // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  const countryOptions = useMemo(() => countryList().getData(), []);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [loading, setLoading] = useState();
  const [predictions1, setPredictions1] = useState()
  const [predictions2, setPredictions2] = useState()
  const [predictions3, setPredictions3] = useState()
  const [searchString1, setSearchString1] = useState()
  const [searchString2, setSearchString2] = useState()
  const [searchString3, setSearchString3] = useState()
  const [location, setLocation] = useState()

  const y_n_options = [
    { value: "y", label: "Yes" },
    { value: "n", label: "No" },
  ];
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors
  } = useForm();

  useEffect(() => {
    getLocation()
    setLoading(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    window.scrollTo(0, 0, 'smooth');
    // setValue('legal_address1', props.data.address_1)
    // setValue('legal_address2', props.data.address_2)

    // setValue('legal_country', props.data.country)
    // setValue('legal_region', props.data.region)
    // setValue('legal_city', props.data.city)
    // setValue('legal_postal', props.data.postal)
    // setValue('request_id',JSON.parse(localStorage.getItem("at_requestor")).id)
  }, []);


  const setAddress = (name, location) => {
    console.log(name)
    if (name === 'address') setValue('address', location)
    else if (name === 'address_primary') setValue('address_primary', location)
    else if (name === 'address_change') setValue('address_change', location)

  }

  const searchAddress = async (e) => {
    console.log(e.target.value.length)
    console.log(e.target.name)
    if (!location) return
    if (!e.target.value || e.target.value.length < 3) {
      if (e.target.name === 'address') setPredictions1()
      else if (e.target.name === 'address_primary') setPredictions2()
      else if (e.target.name === 'address_change') setPredictions3()
      // if(e.target.name === 'address') setPredictions1()
      // setPredictions()
      return
    }
    console.log(location)
    const cords = { lat: location.latitude, lng: location.longitude }
    await apiRequest(`/ccr/verify/addressLookup`, "POST", { address: e.target.value, cords }).then(resp => {
      if (e.target.name === 'address') {
        setSearchString1(e.target.value)
        console.log('address')
        setPredictions1(resp.data.predictions)
      } else if (e.target.name === 'address_primary') {
        setSearchString2(e.target.value)
        console.log('address_primary')
        setPredictions2(resp.data.predictions)
      } else if (e.target.name === 'address_change') {
        setSearchString3(e.target.value)
        console.log('address_primary')
        setPredictions3(resp.data.predictions)
      }
    })
  }

  const getLocation = async () => {
    const location = await locationAccess();
    setLocation(location)
  }



  // const [load, setLoad] = useState(0);
  // if (load < 1) {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   setLoad(2);
  // }

  const onselect = (name, value) => {
    console.log(name);
    console.log(value);
    // console.log(errors);
    if (name === "location_residence" && value === 'n') {
      setValue("address_primary", "");
      setPredictions2()
    }
    else if (name === "address_primary") {
      setValue("address_primary", "");
      setPredictions1()
    }

    if (name === 'location_new') clearErrors('location_new')
    if (name === 'location_residence') clearErrors('location_residence')



  };

  const locationAccess = async () => {
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true, // Request high accuracy (GPS)
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0, // Ensure the result is not a cached position
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setValue("location", { latitude, longitude });
          resolve({ latitude, longitude });
        },
        (err) => {
          // Handle error
          console.log(err);
          resolve({ latitude: null, longitude: null });
        },
        options,
      );
    });
  };

  const onFormSubmit = async (e) => {
    // console.log("form submit");
    console.log(getValues())


    // if (getValues('location_new') === 'n' && !getValues('address')) {
    //   setError('address', { type: 'required', message: 'This field is requiered' })
    // } else if (getValues('location_new') === 'y') {
    //   clearErrors('address')
    // }

    // return
    setSubmitButtonLoading(true);
    const location = await locationAccess();
    setValue("latitude", location.latitude);
    setValue("longitude", location.longitude);
    console.log(getValues())
    try {
      await apiRequest("/ccr/kyc/step/4", "POST", getValues()).then((resp) => {
        if (resp.code === 200) {
          // console.log(resp);
          props.jump(resp.data.step);
        } else {
          console.log("error response: ", resp.message);
          setSubmitButtonLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
      setSubmitButtonLoading(false);
    }
  };

  useEffect(() => {
    console.log(errors)
  }, [errors])

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log(typeof getValues('address'))

    if ((!getValues('address') || getValues('address') === '<empty string>') && !getValues('cantFind1')) {
      console.log('missing address');
      setError('address', { type: "required", message: "This field is required" })
      // setError('address_primary', { type: "required", message: "This field is required" })
      console.log(errors)

    } else if (getValues('address') || getValues('cantFind1')) {
      clearErrors('address')
    }

    // if (!getValues('address_primary') && !getValues('cantFind2')) {
    //   console.log('missing address_primary');
    //   setError('address_primary', { type: "required", message: "This field is required" })
    //   console.log(errors)

    // } else if (getValues('address_primary') || getValues('cantFind2')) {
    //   clearErrors('address_primary')
    // }

    // if (!getValues('address_change') && !getValues('cantFind3')) {
    //   console.log('missing address_change');
    //   setError('address_change', { type: "required", message: "This field is required" })
    //   console.log(errors)

    // } else if (getValues('address_change') || getValues('cantFind3')) {
    //   clearErrors('address_change')
    // }

    console.log(errors)


    // return
    await handleSubmit(onFormSubmit)();
    if (Object.keys(errors).length) {
      toast.error('missing information', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <React.Fragment>
      <Head title="Your Location" />
      {/* <HeaderCCR></HeaderCCR> */}
      <div className="mb-2"></div>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <div className="content-page wide-md m-auto">
            <Block className={submitButtonLoading && "disabled"}>
              <h5>Your Location</h5>
              <p>Provide your address associated with at least one of your ID documents. And if possible, please be at that location.</p>

              <form className="content clearfix" onSubmit={handleFormSubmit}>
                <div className="gy-4 row">
                  <div className="entry">
                    <PreviewAltCard>
                      <Row className="" >
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <label className="form-label" htmlFor="name">
                            Search Address*
                          </label>
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <input
                                disabled={watch('cantFind1')}
                                autoComplete="off"
                                type="text"
                                id='address'
                                value={watch('address') ? watch('address').description : ''}
                                onInputCapture={(e) => searchAddress(e)}
                                {...register('address', {
                                  required: watch('cantFind1') ? false : 'This field is required'
                                })}
                                // onChange={(e) => type === 'file' && onChange ? onChange(name, e.target.files[0]) : onchange}
                                placeholder={'Search'}
                                className={"form-control"}
                              />
                              {errors && errors.address && <p className="invalid">{errors.address.message}</p>}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {!getValues("cantFind1") && predictions1 && searchString1.length >= 3 && (!getValues('address') || typeof getValues('address') !== 'object') && < Row >
                        <ul>
                          {predictions1.map((location) => {
                            return (
                              <li className={"form-control"} onClick={(e) => { setAddress('address', location); clearErrors('address') }}>{location.description}</li>
                            )
                          })}
                        </ul>
                      </Row>
                      }
                      <Row>
                        <Col>
                          <div style={{ paddingLeft: "35px", paddingBottom: "10px" }} className="custom-control-group pt-2">
                            <div className="custom-control custom-checkbox">
                              <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="cantFind1"
                                    checked={watch("cantFind1")}
                                    {...register("cantFind1")}
                                    onChange={(e) => {
                                      setPredictions1(null); setValue('address', null); setValue("cantFind1", !getValues("cantFind1")); e.target.value && clearErrors('address');
                                    }}
                                  />

                                  <label className="custom-control-label" htmlFor="cantFind1">
                                    <div>Can't find your address?</div>
                                  </label>
                                  {errors.cantFind1 && <p className="invalid">{errors.cantFind1.message}</p>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>





                      {watch("cantFind1") && <Row className="">
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <Input
                            type="text"
                            label="Address Line 1"
                            id="legal_address1"
                            className="form-control-lg form-control"
                            name="legal_address1"
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,20}$/,
                                message: "Max 20 characters",
                              },
                            }}
                            errors={errors}
                            register={register}
                          />
                        </Col>
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <Input
                            type="text"
                            label="Address Line 2"
                            id="legal_address2"
                            className="form-control-lg form-control"
                            name="legal_address2"
                            errors={errors}
                            register={register}
                            requiredMessage={{
                              pattern: {
                                value: /^.{1,20}$/,
                                message: "Max 20 characters",
                              },
                            }}
                          />
                        </Col>
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <RSelect2
                            onselect={onselect}
                            setValue={setValue}
                            defaultValue={watch("legal_country")}
                            label="Country"
                            register={register}
                            requiredMessage={"This field is required"}
                            value={countryOptions.find(country => country.value === watch('legal_country'))}
                            id="legal_country"
                            name="legal_country"
                            errors={errors}
                            options={countryOptions}
                            placeholder="Select country"
                            className="form-control-lg p-0 border-0 form-control"
                          />
                        </Col>
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          {watch("legal_country") !== "CA" && watch("legal_country") !== "US" && (
                            <Input
                              type="text"
                              label="Province/State"
                              id="legal_region"
                              className="form-control-lg form-control"
                              name="legal_region"
                              required
                              requiredMessage={{
                                required: "This field is required",
                                pattern: {
                                  value: /^.{1,20}$/,
                                  message: "Max 20 characters",
                                },
                              }}
                              errors={errors}
                              register={register}
                              value={watch('legal_region')}
                            />
                          )}
                          {(watch("legal_country") === "CA" || watch("legal_country") === "US") && (
                            <RSelect2
                              onselect={onselect}
                              setValue={setValue}
                              selectedCountry={watch("legal_region")}
                              label="Province/State"
                              register={register}
                              requiredMessage="This field is required"
                              id="legal_region"
                              name="legal_region"
                              errors={errors}
                              options={regions[watch("legal_country")]}
                              placeholder="Select Region"
                              className="form-control-lg p-0 border-0 form-control"
                              value={regions[watch("legal_country")].find(region => region.value === watch('legal_region'))}
                            />
                          )}
                          {/* {watch("legal_country") === "CA" && (
                          <RSelect2
                            onselect={onselect}
                            setValue={setValue}
                            selectedCountry={watch("legal_region")}
                            label="Region"
                            register={register}
                            requiredMessage="This field is required"
                            id="legal_region"
                            name="legal_region"
                            errors={errors}
                            options={regions[watch("legal_country")]}
                            placeholder="Select Region"
                            className="form-control-lg p-0 border-0 form-control"
                          />
                        )} */}
                        </Col>
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <Input
                            type="text"
                            label="City"
                            id="legal_city"
                            className="form-control-lg form-control"
                            name="legal_city"
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,20}$/,
                                message: "Max 20 characters",
                              },
                            }}
                            errors={errors}
                            register={register}
                          />
                        </Col>

                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <Input
                            type="text"
                            label="Postal/Zip Code"
                            id="legal_postal"
                            className="form-control-lg form-control"
                            name="legal_postal"
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,20}$/,
                                message: "Max 20 characters",
                              },
                            }}
                            errors={errors}
                            register={register}
                          />
                        </Col>

                      </Row>}

                    </PreviewAltCard>
                    <PreviewAltCard>

                      <Row>
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <RSelect2
                            onselect={onselect}
                            setValue={setValue}
                            selectedCountry={watch("location_current")}
                            label="Is this your current location?"
                            register={register}
                            requiredMessage="This field is required"
                            id="location_current"
                            name="location_current"
                            errors={errors}
                            options={y_n_options}
                            placeholder="Select"
                            className="form-control-lg p-0 border-0 form-control"
                          />
                        </Col>
                        <Col>
                          {watch("location_current") === "n" && (
                            // <Textarea
                            //   className="form-control-lg p-0  form-control"
                            //   required={true}
                            //   disabled={false}
                            //   register={register}
                            //   errors={errors}
                            //   type="text"
                            //   label="Please explain why you are not currently at this location"
                            //   id="location_current_explaination"
                            //   name="location_current_explaination"
                            // ></Textarea>
                            <Textarea
                              type="text"
                              className="form-control-lg form-control"
                              required
                              requiredMessage={{
                                required: "This field is required",
                                pattern: {
                                  value: /^.{1,512}$/,
                                  message: "Max 512 characters",
                                },
                              }}
                              errors={errors}
                              register={register}
                              label="Please explain why you are not currently at this location"
                              id="location_current_explaination"
                              name="location_current_explaination"
                            ></Textarea>
                          )}
                        </Col>
                      </Row>
                    </PreviewAltCard>
                    <PreviewAltCard>
                      <Row>
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <RSelect2
                            onselect={onselect}
                            setValue={setValue}
                            selectedCountry={watch("location_residence")}
                            label="Is this your primary residence?"
                            register={register}

                            requiredMessage="This field is required"
                            id="location_residence"
                            name="location_residence"
                            errors={errors}
                            options={y_n_options}
                            placeholder="Select "
                            className="form-control-lg p-0 border-0 form-control"
                          />
                        </Col>
                      </Row>
                      {watch("location_residence") === "n" && (
                        <div className="mt-2">
                          <p className="mb-0">
                            Provide the address of your primary home where you live or designate for most of the year.
                          </p>


                          <Row className="">
                            <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                              <label className="form-label" htmlFor="name">
                                Search Address*
                              </label>
                              <div className="form-group">
                                <div className="form-control-wrap">
                                  <input
                                    disabled={watch('cantFind2')}
                                    autoComplete="off"
                                    type="text"
                                    id='address_primary'
                                    value={watch('address_primary') ? watch('address_primary').description : ''}
                                    onInputCapture={(e) => searchAddress(e)}
                                    {...register('address_primary', { required: !watch('cantFind2') && 'This field is required' })}
                                    // onChange={(e) => type === 'file' && onChange ? onChange(name, e.target.files[0]) : onchange}
                                    placeholder={'Search'}
                                    className={"form-control"}
                                  />

                                  {errors['address_primary'] && <p className="invalid">{errors['address_primary'].message}</p>}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <div style={{ paddingLeft: "50px", paddingBottom: "10px" }} className="custom-control-group pt-2">
                              <div className="custom-control custom-checkbox">
                                <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="cantFind2"
                                      checked={watch("cantFind2")}
                                      {...register("cantFind2")}
                                      onChange={() => {
                                        setPredictions2(null); setValue('address_primary', null); setValue("cantFind2", !getValues("cantFind2"));
                                      }}
                                    />

                                    <label className="custom-control-label" htmlFor="cantFind2">
                                      <div>Can't find your address?</div>
                                    </label>
                                    {errors.cantFind2 && <p className="invalid">{errors.cantFind2.message}</p>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Row>

                          {!getValues("cantFind2") && predictions2 && searchString2.length >= 3 && (!getValues('address_primary') || typeof getValues('address_primary') !== 'object') && < Row >
                            <ul>
                              {predictions2.map((location) => {
                                return (
                                  <li className={"form-control"} onClick={(e) => { setAddress('address_primary', location); clearErrors('address_primary') }}>{location.description}</li>
                                )
                              })}
                            </ul>
                          </Row>
                          }

                          {watch('cantFind2') && <Row className="pt-4">
                            <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                              <Input
                                type="text"
                                label="Address Line 1"
                                id="residence_address1"
                                className="form-control-lg form-control"
                                name="residence_address1"
                                required
                                requiredMessage={{
                                  required: "This field is required",
                                  pattern: {
                                    value: /^.{1,20}$/,
                                    message: "Max 20 characters",
                                  },
                                }}
                                errors={errors}
                                register={register}
                              />
                            </Col>
                            <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                              <Input
                                type="text"
                                label="Address Line 2"
                                id="residence_address2"
                                className="form-control-lg form-control"
                                name="residence_address2"
                                errors={errors}
                                requiredMessage={{
                                  pattern: {
                                    value: /^.{1,20}$/,
                                    message: "Max 20 characters",
                                  },
                                }}
                                register={register}
                              />
                            </Col>

                            <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                              <RSelect2
                                onselect={onselect}
                                setValue={setValue}
                                selectedCountry={watch("location_residence")}
                                register={register}
                                requiredMessage="This field is required"
                                errors={errors}
                                options={countryOptions}
                                placeholder="Select country"
                                label="Country"
                                id="residence_country"
                                name="residence_country"
                                className="form-control-lg p-0 border-0 form-control"
                              />
                            </Col>
                            <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                              {watch("residence_country") !== "CA" && watch("residence_country") !== "US" && (
                                <Input
                                  type="text"
                                  className="form-control-lg form-control"
                                  required
                                  requiredMessage={{
                                    required: "This field is required",
                                    pattern: {
                                      value: /^.{1,20}$/,
                                      message: "Max 20 characters",
                                    },
                                  }}
                                  errors={errors}
                                  register={register}
                                  label="Province/State"
                                  id="residence_region"
                                  name="residence_region"
                                />
                              )}
                              {watch("residence_country") === "US" && (
                                <RSelect2
                                  onselect={onselect}
                                  setValue={setValue}
                                  selectedCountry={watch("residence_region")}
                                  register={register}
                                  requiredMessage="This field is required"
                                  errors={errors}
                                  options={regions[watch("residence_country")]}
                                  placeholder="Select Region"
                                  className="form-control-lg p-0 border-0 form-control"
                                  label="Province/State"
                                  id="residence_region"
                                  name="residence_region"
                                />
                              )}
                              {watch("residence_country") === "CA" && (
                                <RSelect2
                                  onselect={onselect}
                                  setValue={setValue}
                                  selectedCountry={watch("residence_region")}
                                  register={register}
                                  requiredMessage="This field is required"
                                  errors={errors}
                                  options={regions[watch("residence_country")]}
                                  placeholder="Select Region"
                                  className="form-control-lg p-0 border-0 form-control"
                                  label="Province/State"
                                  id="residence_region"
                                  name="residence_region"
                                />
                              )}
                            </Col>

                            <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                              <Input
                                type="text"
                                className="form-control-lg form-control"
                                required
                                requiredMessage={{
                                  required: "This field is required",
                                  pattern: {
                                    value: /^.{1,20}$/,
                                    message: "Max 20 characters",
                                  },
                                }}
                                errors={errors}
                                register={register}
                                label="City"
                                id="residence_city"
                                name="residence_city"
                              />
                            </Col>
                            <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                              <Input
                                type="text"
                                className="form-control-lg form-control"
                                required
                                requiredMessage={{
                                  required: "This field is required",
                                  pattern: {
                                    value: /^.{1,20}$/,
                                    message: "Max 20 characters",
                                  },
                                }}
                                errors={errors}
                                register={register}
                                label="Postal/Zip Code"
                                id="residence_postal"
                                name="residence_postal"
                              />
                            </Col>
                          </Row>}
                        </div>
                      )}
                    </PreviewAltCard>
                    <PreviewAltCard>

                      <Row>
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <RSelect2
                            onselect={onselect}
                            setValue={setValue}
                            selectedCountry={watch("location_residence")}
                            label="Will your address change? (eg: your transaction involves buying a new home)"
                            register={register}
                            requiredMessage="This field is required"
                            id="location_new"
                            name="location_new"
                            errors={errors}
                            options={y_n_options}
                            placeholder="Select"
                            className="form-control-lg p-0 border-0 form-control"
                          />
                        </Col>
                      </Row>

                      {watch("location_new") === "y" && (
                        <div className="mt-4">
                          <p className="mb-0">
                            Provide the address of your new home.
                          </p>

                          <Row className="" >
                            <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                              <label className="form-label" htmlFor="name">
                                Search Address*
                              </label>
                              <div className="form-group">
                                <div className="form-control-wrap">
                                  <input
                                    disabled={watch('cantFind3')}
                                    autoComplete="off"
                                    type="text"
                                    id='address_change'
                                    value={watch('address_change') ? watch('address_change').description : ''}
                                    onInputCapture={(e) => searchAddress(e)}
                                    {...register('address_change', { required: !watch('cantFind3') && 'This field is required' })}
                                    // onChange={(e) => type === 'file' && onChange ? onChange(name, e.target.files[0]) : onchange}
                                    placeholder={'Search'}
                                    className={"form-control"}
                                  />
                                  {errors && errors.address_change && <p className="invalid">{errors.address_change.message}</p>}
                                </div>
                              </div>
                            </Col>

                          </Row>
                          {!getValues("cantFind3") && predictions3 && searchString3.length >= 3 && (!getValues('address_change') || typeof getValues('address_change') !== 'object') && < Row >
                            <ul>
                              {predictions3.map((location) => {
                                return (
                                  <li className={"form-control"} onClick={(e) => { setAddress('address_change', location); clearErrors('address_change') }}>{location.description}</li>
                                )
                              })}
                            </ul>
                          </Row>}
                          <Row>
                            <Col>
                              <div style={{ paddingLeft: "35px", paddingBottom: "10px" }} className="custom-control-group pt-2">
                                <div className="custom-control custom-checkbox">
                                  <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="cantFind3"
                                        checked={watch("cantFind3")}
                                        {...register("cantFind3")}
                                        onChange={() => {
                                          setPredictions3(null); setValue('address_change', null); setValue("cantFind3", !getValues("cantFind3"));
                                        }}
                                      />

                                      <label className="custom-control-label" htmlFor="cantFind3">
                                        <div>Can't find your address?</div>
                                      </label>
                                      {errors.cantFind3 && <p className="invalid">{errors.cantFind3.message}</p>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          {watch('cantFind3') &&

                            <Row className="pt-4">
                              <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                                <Input
                                  type="text"
                                  label="Address Line 1"
                                  id="location_new_address1"
                                  className="form-control-lg form-control"
                                  name="location_new_address1"
                                  required
                                  requiredMessage={{
                                    required: "This field is required",
                                    pattern: {
                                      value: /^.{1,20}$/,
                                      message: "Max 20 characters",
                                    },
                                  }}
                                  errors={errors}
                                  register={register}
                                />
                              </Col>
                              <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                                <Input
                                  type="text"
                                  label="Address Line 2"
                                  id="location_new_address2"
                                  className="form-control-lg form-control"
                                  name="location_new_address2"
                                  errors={errors}
                                  requiredMessage={{
                                    pattern: {
                                      value: /^.{1,20}$/,
                                      message: "Max 20 characters",
                                    },
                                  }}
                                  register={register}
                                />
                              </Col>

                              <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                                <RSelect2
                                  onselect={onselect}
                                  setValue={setValue}
                                  selectedCountry={watch("location_residence")}
                                  register={register}
                                  requiredMessage="This field is required"
                                  errors={errors}
                                  options={countryOptions}
                                  placeholder="Select country"
                                  label="Country"
                                  id="location_new_country"
                                  name="location_new_country"
                                  className="form-control-lg p-0 border-0 form-control"
                                />
                              </Col>
                              <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                                {watch("location_new_country") !== "CA" && watch("new_country") !== "US" && (
                                  <Input
                                    type="text"
                                    className="form-control-lg form-control"
                                    required
                                    requiredMessage={{
                                      required: "This field is required",
                                      pattern: {
                                        value: /^.{1,20}$/,
                                        message: "Max 20 characters",
                                      },
                                    }}
                                    errors={errors}
                                    register={register}
                                    label="Province/State"
                                    id="location_new_region"
                                    name="location_new_region"
                                  />
                                )}
                                {watch("location_new_ountry") === "US" && (
                                  <RSelect2
                                    onselect={onselect}
                                    setValue={setValue}
                                    selectedCountry={watch("location_new_region")}
                                    register={register}
                                    requiredMessage="This field is required"
                                    errors={errors}
                                    options={regions[watch("location_new_country")]}
                                    placeholder="Select Region"
                                    className="form-control-lg p-0 border-0 form-control"
                                    label="Province/State"
                                    id="location_new_region"
                                    name="location_new_egion"
                                  />
                                )}
                                {watch("location_new_country") === "CA" && (
                                  <RSelect2
                                    onselect={onselect}
                                    setValue={setValue}
                                    selectedCountry={watch("location_new_egion")}
                                    register={register}
                                    requiredMessage="This field is required"
                                    errors={errors}
                                    options={regions[watch("location_new_country")]}
                                    placeholder="Select Region"
                                    className="form-control-lg p-0 border-0 form-control"
                                    label="Province/State"
                                    id="location_new_region"
                                    name="location_new_region"
                                  />
                                )}
                              </Col>

                              <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                                <Input
                                  type="text"
                                  className="form-control-lg form-control"
                                  required
                                  requiredMessage={{
                                    required: "This field is required",
                                    pattern: {
                                      value: /^.{1,20}$/,
                                      message: "Max 20 characters",
                                    },
                                  }}
                                  errors={errors}
                                  register={register}
                                  label="City"
                                  id="location_new_city"
                                  name="location_new_city"
                                />
                              </Col>
                              <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                                <Input
                                  type="text"
                                  className="form-control-lg form-control"
                                  required
                                  requiredMessage={{
                                    required: "This field is required",
                                    pattern: {
                                      value: /^.{1,20}$/,
                                      message: "Max 20 characters",
                                    },
                                  }}
                                  errors={errors}
                                  register={register}
                                  label="Postal/Zip Code"
                                  id="location_new_postal"
                                  name="location_new_postal"
                                />
                              </Col>
                            </Row>

                          }

                        </div>
                      )}
                    </PreviewAltCard>

                    <div className="mt-3 mb-5 nk-kycfm-note">
                      <em className=" icon ni ni-info-fill"></em>
                      <p>
                        We use your current location to verify that it matches your address on at least one of your ID
                        documents.
                      </p>
                    </div>

                    <div className="bg-primary" style={{ position: "fixed", bottom: "0px" }}>
                      <div className="bg-white col-12" style={{ position: "fixed", bottom: "0px", right: "0px" }}>
                        <div style={{ margin: "10px 5px 10px 0px", float: "right" }} className="p-1">
                          {" "}
                          <Button color="primary" size="" className="" outline="">
                            {submitButtonLoading && <Spinner size="sm" />} Continue
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="actions clearfix">
                    <ul>
                      <li>
                        <button type="submit" style={{float:'right'}} className="btn btn-primary">Next</button>
                      </li>
                    </ul>
                  </div> */}
              </form>
            </Block >
          </div >
        </Content >
      )}
      <ToastContainer />
    </React.Fragment >
  );
};

export default Location;
