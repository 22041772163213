import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import { apiRequest } from "../../utils/Api";
import Cookies from "js-cookie";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { checkJWTExpiration } from "../../utils/Utils";
import { toast, ToastContainer } from "react-toastify";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState();
  const [twoFactor, setTwoFactor] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/customer"
  console.log(location)

  useEffect(() => {
    const checkToken = async () => {
      if (await checkJWTExpiration()) window.location.href = "/customer";
    };
    checkToken();
  }, []);

  const onFormSubmit = async (formData) => {
    setError("");
    setValue('step', twoFactor ? 'second' : 'first')

    setLoading(true);
    try {
      await apiRequest("/customer/login", "POST", getValues()).then((resp) => {
        console.log(resp);
        if (resp.code === 200) {
          localStorage.setItem("token", resp.message);
          // Cookies.set("token", fetchResponse.message, {
          //   path: "/",
          //   // domain: ".athenty.com",
          //   expires: 2,
          // }); // TODO: fix setting from API
          console.log(from)
          if (from === '/customer/log-out') {
            navigate('/customer')
          } else {
            navigate(from);
          }
        } else if (resp.code === 280) {
          setTwoFactor(true)
          toast.success(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          setError(resp.message);
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
      setError("Something went wrong. Please contact support");
    }
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  return (
    <>
      <Head title="Login" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>

        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
            <div style={{ display: twoFactor ? 'none' : 'block' }} className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="login_email">
                  Email<span className="text-danger">*</span>
                </label>
              </div>
              <div className="form-control-wrap">
                <input
                  disabled={twoFactor}
                  type="text"
                  id="login_email"
                  tabIndex={1}
                  {...register("login_email", { required: "This field is required" })}
                  placeholder="Enter your email address"
                  className="form-control-lg form-control"
                />
                {errors.login_email && <span className="invalid">{errors.login_email.message}</span>}
              </div>
            </div>
            <div style={{ display: twoFactor ? 'none' : 'block' }} className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="login_password">
                  Passcode<span className="text-danger">*</span>
                </label>
                {!twoFactor && <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                  Forgot password?
                </Link>}
              </div>
              <div className="form-control-wrap">
                <a
                  href="#login_password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassState(!passState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  disabled={twoFactor}
                  type={passState ? "text" : "password"}
                  id="login_password"
                  tabIndex={2}
                  {...register("login_password", { required: "This field is required" })}
                  placeholder="Enter your password"
                  className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                />
                {errors.login_password && <span className="invalid">{errors.login_password.message}</span>}
              </div>
            </div>
            <div className="form-group">


              <div className="custom-control custom-control-md custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="remember"
                  {...register("remember")}
                />
                <label className="custom-control-label" htmlFor="remember">
                  Remember Me
                </label>
              </div>


            </div>
            {twoFactor && <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="2fa">
                  Two factor code<span className="text-danger">*</span>
                </label>

              </div>
              <div className="form-control-wrap">

                <input type="password" style={{ display: "none" }}></input>
                <input
                  type="text"
                  id="two_factor"
                  name="two_factor"
                  autoComplete="off"
                  tabIndex={2}
                  {...register("two_factor", { required: "This field is required" })}
                  placeholder="Enter your password"
                  className="form-control-lg form-control"
                />
                {errors.two_factor && <span className="invalid">{errors.two_factor.message}</span>}
              </div>
            </div>}
            <div className="form-group">
              {errorVal && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    <Icon name="alert-circle" /> {errorVal}
                  </Alert>
                </div>
              )}
              <Button size="lg" className="btn-block" type="submit" color="primary" tabIndex={3}>
                {loading ? <Spinner size="sm" color="light" /> : "Log In"}
              </Button>
            </div>
          </Form>
          {/* <div className="form-note-s2 text-center pt-4">
            New on our Athenty? <Link to={`${process.env.PUBLIC_URL}/auth-register`}>Create an account</Link>
          </div> */}
        </PreviewCard>
      </Block>
      <ToastContainer />
      {/* <AuthFooter /> */}
    </>
  );
};
export default Login;
