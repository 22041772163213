import React, { useMemo, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import makeAnimated from "react-select/animated";
import { regions } from "../../data/country";
// import { colourOptions } from '../data';

import {
  Block,
  Input,
  Button,
  DocumentScan,
  Loading,
  PreviewCard,
  // Select,
} from "../../../components/Component";
import { useForm } from "react-hook-form";
import RSelect2 from "../../../components/select/ReactSelect2";
import countryList from "react-select-country-list";
import { apiRequest } from "../../../utils/Api";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Col, Row, Spinner } from "reactstrap";

const entity_options = [
  { value: "1", label: "Corporation" },
  { value: "2", label: "Unlimited Liability Company (ULC)" },
  { value: "3", label: "Limited Liability Company (LLC)" },
  { value: "4", label: "General Partnership (GP)" },
  { value: "5", label: "Limited Partnership (LP)" },
  { value: "6", label: "Limited Liability Partnership (LLP)" },
  { value: "7", label: "Joint Venture or Syndicate" },
  { value: "8", label: "Trust" },
  { value: "9", label: "Co-operative Corporation" },
];
const roleOptions = [
  { value: "director", label: "Director" },
  { value: "officer", label: "Officer" },
  { value: "shareholder", label: "Shareholder" },
  { value: "general_partner", label: "General Partner" },
  { value: "limited_partner", label: "Limited Partner" },
  { value: "trustee", label: "Trustee" },
  { value: "setlor", label: "Setlor" },
  { value: "beneficiary", label: "Beneficiary" },
];

const signOptions = [
  { label: "President", value: "pres" },
  { label: "Vice-President", value: "vp" },
  { label: "Secretary", value: "sec" },
  { label: "Treasurer", value: "tres" },
  { label: "Another Director", value: "dir" },
  { label: "Another Officer", value: "off" },
  // { label: "Other", value: "other" },
];

const officerRoleOptions = [
  { value: "d", label: "Director" },
  { value: "o", label: "Officer" },
  { value: "p", label: "Partner" },
  { value: "other", label: "Other" },
];
const officerTitleOptions = [
  { value: "pres", label: "President" },
  { value: "vp", label: "Vice-President" },
  { value: "sec", label: "Secretary" },
  { value: "tres", label: "Treasurer" },
  { value: "other", label: "Other" },
];



const partnerOptions = [
  { value: "genp", label: "General Partner" },
  { value: "limp", label: "Limited Partner" },
]

const otherOptions = [
  { value: "trust", label: "Trustee" },
  { value: "benf", label: "Beneficiary" },
  { value: "sett", label: "Settlor" },
  { value: "coo", label: "Co-Owner/JV" },
  { value: "oth", label: "Other" },
]

const officerSignTogetherOptions = [
  { value: "0", label: "Alone" },
  { value: "1", label: "Together" },
  { value: "2", label: "Some other way" },
];

const Business = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const animatedComponents = makeAnimated();
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [predictions1, setPredictions1] = useState()
  const [searchString1, setSearchString1] = useState()
  const [location, setLocation] = useState()
  const [reseting, setReseting] = useState(true)



  const [load, setLoad] = useState(0);
  if (load < 1) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoad(2);
  }


  useEffect(() => {
    getLocation()
    setLoading(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const getLocation = async () => {
    const location = await locationAccess();
    setLocation(location)
  }

  const setAddress = (name, location) => {
    console.log(name)
    if (name === 'searched_address') setValue('searched_address', location)
  }

  const searchAddress = async (e) => {
    console.log(e.target.value.length)
    console.log(e.target.name)
    console.log(location)
    if (!location) return
    if (!e.target.value || e.target.value.length < 3) {
      if (e.target.name === 'searched_address') setPredictions1()
      // if(e.target.name === 'address') setPredictions1()
      // setPredictions()
      return
    }
    console.log(location)
    const cords = { lat: location.latitude, lng: location.longitude }
    await apiRequest(`/ccr/verify/addressLookup`, "POST", { address: e.target.value, cords }).then(resp => {
      if (e.target.name === 'searched_address') {
        setSearchString1(e.target.value)
        console.log('searched_address')
        setPredictions1(resp.data.predictions)
      }
    })
  }


  const [formData, setFormData] = useState({
    title: "",
    name: "",
    image1: "",
    business_activity: "",
    business_phone: "",
    business_domain: "",
    address_1: "",
    address_2: "",
    city: "",
    country: "",
    region: "",
    postal: "",
    business_role: [],
    percent_ownership: 0,
    officers: [],
  });

  const {
    reset,
    unregister,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = useForm();

  const onselect = (name, value) => {
    console.log(name);
    console.log(value);

    if (name === "is_business" && value === "n") {
      console.log('is_business')
      reset();
    }
    if (name === "country") {
      setValue("region", "");
    }
    if (name === "residence_country") {
      setValue("residence_region", "");
    }
    if (name === 'cosign') {
      console.log('cosign')
      setValue('cosign_other', null)
      setValue('cosign_together', null)
    }
    if (name.includes('officer_role_')) {
      console.log(`officer_title_${name.split('_')[2]}`)

      unregister(`officer_title_${name.split('_')[2]}`)
      register(`officer_title_${name.split('_')[2]}`)
      // reset({ [`officer_title_${name.split('_')[2]}`]: null })

      // setValue('cosign_together')
    }
    if (getValues(name)) {
      delete errors[name];
      // console.log(errors);
    }
    console.log(getValues())
  };

  const signChange = (index) => {
    setValue(`officer_sign_other_way_${index}`, null);
    setValue(`officer_sign_together_${index}`, null);
  };

  const y_n_options = [
    { value: "y", label: "Yes" },
    { value: "n", label: "No" },
  ];

  const day_options = Array.from({ length: 31 }, (_, i) => ({
    value: (i + 1).toString().padStart(2, '0'),
    label: (i + 1).toString().padStart(2, '0'),
  }));

  const month_options = Array.from({ length: 12 }, (_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  }));

  const year_options = Array.from({ length: 120 }, (_, i) => ({
    value: (Number(new Date().getFullYear()) - i).toString(),
    label: (Number(new Date().getFullYear()) - i).toString(),
  }));

  const AddOfficer = () => {
    const formCopy = { ...formData };
    formCopy.officers.push({});
    setFormData(formCopy);
    // console.log(formData);
    // setOfficers([...formData.officers, obj]);
    // console.log(officers);
  };

  const removeOfficer = (index) => {
    const formCopy = { ...formData };
    formCopy.officers.pop(index);
    console.log(formCopy.officers)
    setFormData(formCopy);
    setValue(`officer_email_${index}`, null);
    setValue(`officer_first_name_${index}`, null);
    setValue(`officer_last_name_${index}`, null);
    setValue(`officer_role_${index}`, null);
    setValue(`officer_sign_${index}`, null);
    setValue(`officer_title_${index}`, null);
    setValue(`officer_sign_other_way_${index}`, null);
    setValue(`officer_sign_together_${index}`, null);
  };

  const locationAccess = async () => {
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true, // Request high accuracy (GPS)
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0, // Ensure the result is not a cached position
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setValue("location", { latitude, longitude });
          resolve({ latitude, longitude });
        },
        (err) => {
          // Handle error
          console.log(err);
          resolve({ latitude: null, longitude: null });
        },
        options,
      );
    });
  };

  const onFormSubmit = async () => {
    console.log("submit");
    // setSubmitButtonLoading(true);
    console.log(getValues())

    const location = await locationAccess();
    setValue("latitude", location.latitude);
    setValue("longitude", location.longitude);
    if (getValues("is_business") === "n") {
      console.log("no");
      try {
        await apiRequest("/ccr/kyc/step/9", "POST", getValues()).then((resp) => {
          if (resp.code === 200) {
            console.log(resp);
            props.jump(resp.data.step);
          } else {
            setSubmitButtonLoading(false);
            console.log("error response: ", resp);
          }
        });
      } catch (err) {
        console.log(err);
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setSubmitButtonLoading(false);
      }
    } else {
      console.log(getValues());
      const data = new FormData();
      data.append("is_business", getValues("is_business") || null);
      data.append("entity_id", getValues("entity") || null);
      data.append("name", getValues("business_name") || null);
      data.append("name_trade", getValues("name_trade") || null);
      data.append("business_document_scan", getValues("document")[0] || null);
      data.append("activity", getValues("business_activity") || null);
      data.append("phone", getValues("business_phone") || null);
      data.append("domain", getValues("business_domain") || null);
      data.append("address1", getValues("address_1") || null);
      data.append("address2", getValues("address_2") || null);
      data.append("country", getValues("country") || null);
      data.append("region", getValues("region") || null);
      data.append("city", getValues("city") || null);
      data.append("postal", getValues("postal") || null);

      data.append("bus_role", JSON.stringify(getValues("business_role") || null));
      data.append("owner_perc", getValues("percent_ownership") || null);
      data.append("month", getValues("month") || null);
      data.append("year", getValues("year") || null);
      data.append("day", getValues("day") || null);
      data.append("latitude", location.latitude || null);
      data.append("longitude", location.longitude || null);
      data.append('searched_address', getValues('searched_address') ? JSON.stringify(getValues('searched_address')) : null)
      data.append('cosign', getValues('cosign'))
      if (getValues('cosign') === '1') {
        data.append('cosign_together', getValues('cosign_together'))

      } else if (getValues('cosign') === '2') {
        data.append('cosign_other', getValues('cosign_other_way'))
      }
      // data.append("title", getValues("title"));
      let i = 0;
      let numberOfOfficers = 0;
      while (getValues(`officer_role_${i}`)) {
        numberOfOfficers++;
        i++;
      }
      console.log(numberOfOfficers);
      let officers = [];
      for (let i = 0; i < numberOfOfficers; i++) {
        officers.push({
          email: getValues(`officer_email_${i}`),
          name_first: getValues(`officer_first_name_${i}`),
          name_last: getValues(`officer_last_name_${i}`),
          role: getValues(`officer_role_${i}`),
          cosign: getValues(`officer_sign_${i}`),
          title: getValues(`officer_title_${i}`),
          other_title: getValues(`officer_title_other_${i}`),
          other: getValues(`officer_sign_other_way_${i}`) || getValues(`officer_sign_together_other_value${i}`),
          together: getValues(`officer_sign_together_${i}`),
        });
      }
      console.log(officers);
      if (officers.length > 0) {
        data.append("officers", JSON.stringify(officers));
      }
      console.log(getValues("business_role"));
      console.log(data);

      setLoading(false)
      // return
      try {
        await apiRequest("/ccr/kyc/step/9", "POST", data, "file").then((resp) => {
          resp = JSON.parse(resp);
          if (resp.code === 200) {
            console.log(resp);
            console.log(localStorage.getItem("at_requestor"));
            localStorage.setItem("at_position", JSON.stringify({ step: resp.data.step }));
            props.jump(resp.data.step);
          } else {
            console.log("error response: ", resp.message);
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // console.log('submit');

    await handleSubmit(onFormSubmit)();

    if (Object.keys(errors).length) {
      toast.error('missing information', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <React.Fragment>
      <Head title="Business" />
      {/* <HeaderCCR></HeaderCCR> */}
      <div className="mb-2"></div>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <div className="content-page wide-md m-auto">
            <Block className={submitButtonLoading && "disabled"}>
              <h5>Business Profile</h5>
              <p>Please make sure all the information you have provided is correct and true.</p>

              <form className="content clearfix" onSubmit={handleFormSubmit}>
                <div className="gy-4 row pb-5">

                  <div className="entry">
                    <RSelect2

                      onselect={onselect}
                      setValue={setValue}
                      selectedAct={watch("is_business")}
                      label="Is this verification regarding a business? (eg: You are being verified as the director, officer, shareholder, partner, trustee, receiver, owner or otherwise because the matter involves a business)"
                      register={register}
                      requiredMessage="This field is required"
                      id="is_business"
                      name="is_business"
                      errors={errors}
                      options={y_n_options}
                      placeholder="Select"
                      className="form-control-lg p-0 border-0 form-control"
                    />
                  </div>
                  {watch("is_business") === "y" && (
                    <div className="mt-2">
                      <div className="form-control-wrap">
                        <div>
                          <RSelect2
                            onselect={onselect}
                            setValue={setValue}
                            selectedAct={watch("request_id")}
                            register={register}
                            requiredMessage="This field is required"
                            id="entity"
                            name="entity"
                            label="Entity"
                            errors={errors}
                            options={entity_options}
                            placeholder="Select"
                            className="form-control-lg p-0 border-0 form-control"
                          />
                        </div>
                      </div>
                      <Input
                        errors={errors}
                        required
                        requiredMessage={{
                          required: "This field is required",
                          pattern: {
                            value: /^.{1,80}$/,
                            message: "Max 128 characters",
                          },
                        }}
                        register={register}
                        type="text"
                        label="Legal Name of Entity"
                        id="business_name"
                        name="business_name"
                        className="form-control-lg form-control"
                      ></Input>
                      <Input
                        errors={errors}
                        requiredMessage={{
                          pattern: {
                            value: /^.{1,80}$/,
                            message: "Max 80 characters",
                          },
                        }}
                        register={register}
                        type="text"
                        label="Trade Name of Entity (if different)"
                        id="name_trade"
                        name="name_trade"
                        className="form-control-lg form-control"
                      ></Input>
                      <Row>
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            Date of Formation
                            {/* {watch('year')<span className="text-danger">*</span>} */}
                          </label>
                        </div>
                        <Col className="mt-1 mb-1 col-sm-4 col-4 col-md-4">
                          <RSelect2
                            onNameChange={watch("day")}
                            onselect={onselect}
                            setValue={setValue}
                            // selectedTitle={watch("title")}
                            // label="date of birth"
                            // requiredMessage="This field is required"
                            errors={errors}
                            register={register}
                            options={day_options}
                            name="day"
                            color="#f5f5f5"
                            id="sex"
                            className="form-control-lg p-0 border-0 form-control"
                            placeholder='day'
                          />
                        </Col>
                        <Col className="mt-1 mb-1 col-sm-4 col-4 col-md-4">
                          <RSelect2
                            onNameChange={watch("month")}
                            onselect={onselect}
                            setValue={setValue}
                            // selectedTitle={watch("title")}
                            // label="date of birth"
                            // requiredMessage="This field is required"
                            errors={errors}
                            register={register}
                            options={month_options}
                            name="month"
                            color="#f5f5f5"
                            id="month"
                            className="form-control-lg p-0 border-0 form-control"
                            placeholder='Month'
                          />
                        </Col>
                        <Col className="mt-1 mb-1 col-sm-4 col-4 col-md-4">
                          <RSelect2
                            onNameChange={watch("year")}
                            onselect={onselect}
                            setValue={setValue}
                            // selectedTitle={watch("title")}
                            // label="date of birth"
                            // requiredMessage="This field is required"
                            errors={errors}
                            register={register}
                            options={year_options}
                            name="year"
                            color="#f5f5f5"
                            id="year"
                            className="form-control-lg p-0 border-0 form-control"
                            placeholder='Year'
                          />
                        </Col>
                      </Row>
                      {/* <Input
                        onNameChange={watch("dof")}
                        type="date"
                        label="Date of Formation"
                        id="dof"
                        name="dof"
                        className="form-control-lg form-control date-picker-alt"
                        register={register}
                        errors={errors}
                      ></Input> */}
                      <DocumentScan
                        value={watch("document")}
                        requiredMessage="This field is required"
                        required
                        errors={errors}
                        capture="environment"
                        title="Upload formation documents (eg: Articles of incorporation): "
                        id="document"
                        register={register}
                        name="document"
                        onChange={setValue}
                        view="0 0 512 512"
                        trans="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        pri="M871 5104 c-205 -55 -369 -264 -416 -529 -13 -77 -15 -321 -15 -1951 l0 -1864 -142 0 c-188 -1 -234 -20 -273 -113 -20 -48 -19 -78 5 -173 61 -239 229 -423 423 -464 36 -7 535 -10 1657 -8 l1605 3 55 22 c170 70 300 232 363 451 l22 77 3 1903 2 1902 400 0 c423 0 442 2 494 48 26 23 56 91 56 128 0 55 -39 187 -82 274 -74 149 -201 258 -345 295 -85 22 -3729 21 -3812 -1z m3365 -145 c-78 -85 -131 -186 -169 -319 l-22 -75 -5 -2000 c-6 -2230 1 -2036 -75 -2190 -125 -257 -380 -328 -571 -161 -86 74 -154 207 -181 351 -18 98 -47 145 -105 174 l-44 21 -1252 0 -1252 0 2 1898 c3 1778 4 1901 21 1957 42 144 133 278 223 331 94 55 0 53 1798 53 l1668 1 -36 -41z m475 8 c70 -32 128 -83 175 -154 44 -66 89 -178 99 -245 6 -39 4 -49 -14 -67 -21 -21 -28 -21 -416 -21 l-396 0 6 38 c42 248 157 412 328 468 60 20 153 12 218 -19z m-1641 -4347 c11 -11 20 -32 20 -47 0 -97 79 -282 166 -386 l55 -67 -1423 2 -1423 3 -55 26 c-72 34 -129 85 -176 156 -44 66 -89 178 -99 245 -6 39 -4 49 14 67 l21 21 1440 0 c1427 0 1440 0 1460 -20z"
                        sec="M2900 4780 c-11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 19 -19 33 -20 460 -20 427 0 441 1 460 20 11 11 20 29 20 40 0 11 -9 29 -20 40 -19 19 -33 20 -460 20 -427 0 -441 -1 -460 -20z M3220 4460 c-11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 19 -19 33 -20 300 -20 267 0 281 1 300 20 11 11 20 29 20 40 0 11 -9 29 -20 40 -19 19 -33 20 -300 20 -267 0 -281 -1 -300 -20z M820 1340 c-11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 19 -19 33 -20 300 -20 267 0 281 1 300 20 11 11 20 29 20 40 0 11 -9 29 -20 40 -19 19 -33 20 -300 20 -267 0 -281 -1 -300 -20z M820 1020 c-11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 19 -19 33 -20 460 -20 427 0 441 1 460 20 25 25 25 55 0 80 -19 19 -33 20 -460 20 -427 0 -441 -1 -460 -20z"
                        ter="M2255 4079 c-52 -12 -1041 -532 -1079 -567 -48 -45 -56 -78 -56 -229 0 -113 4 -145 19 -179 21 -45 56 -77 107 -96 l34 -12 0 -408 c0 -380 -1 -409 -17 -414 -49 -15 -88 -42 -112 -78 -24 -36 -26 -50 -29 -162 -4 -144 11 -192 74 -240 l37 -29 1087 0 1087 0 37 29 c63 48 78 96 74 240 -3 112 -5 126 -29 162 -26 38 -47 54 -101 74 l-28 10 0 408 0 408 34 12 c51 19 86 51 107 96 15 34 19 66 19 179 0 155 -8 186 -61 232 -47 41 -1010 546 -1073 562 -56 15 -73 15 -131 2z m180 -150 c279 -143 925 -486 943 -502 21 -19 22 -28 22 -145 0 -103 -3 -129 -17 -144 -15 -17 -61 -18 -1061 -18 -959 0 -1047 1 -1064 17 -16 14 -18 33 -18 144 l0 128 33 24 c41 31 949 508 996 524 48 16 94 8 166 -28z m-795 -1339 l0 -410 -120 0 -120 0 0 410 0 410 120 0 120 0 0 -410z m440 0 l0 -410 -160 0 -160 0 0 410 0 410 160 0 160 0 0 -410z m360 0 l0 -410 -120 0 -120 0 0 410 0 410 120 0 120 0 0 -410z m440 0 l0 -410 -160 0 -160 0 0 410 0 410 160 0 160 0 0 -410z m360 0 l0 -410 -120 0 -120 0 0 410 0 410 120 0 120 0 0 -410z m140 -550 c17 -17 20 -33 20 -122 0 -80 -3 -105 -17 -120 -15 -17 -61 -18 -1061 -18 -959 0 -1047 1 -1064 17 -15 14 -18 32 -18 120 0 90 3 106 20 123 20 20 33 20 1060 20 1027 0 1040 0 1060 -20z M2268 3731 c-71 -23 -128 -100 -128 -172 1 -50 33 -112 76 -145 33 -25 46 -29 104 -29 58 0 71 4 104 29 43 33 75 95 76 145 0 50 -37 119 -79 147 -41 26 -111 38 -153 25z m92 -131 c24 -24 25 -48 4 -78 -31 -44 -104 -16 -104 40 0 24 35 58 60 58 11 0 29 -9 40 -20z"
                      />

                      <Input
                        errors={errors}
                        required
                        requiredMessage={{
                          required: "This field is required",
                          pattern: {
                            value: /^.{1,80}$/,
                            message: "Max 80 characters",
                          },
                        }}
                        register={register}
                        type="text"
                        label="Business Activity"
                        id="business_activity"
                        name="business_activity"
                        className="form-control-lg form-control"
                      ></Input>

                      <Input
                        errors={errors}
                        required
                        requiredMessage={{
                          required: "This field is required",
                          pattern: {
                            value: /^\+?1?[2-9][0-8][0-9]\d{3}\d{4}$/,
                            message: "Please input valid phone number",
                          },
                        }}
                        register={register}
                        type="text"
                        label="Business Phone"
                        id="business_phone"
                        name="business_phone"
                        className="form-control-lg form-control"
                      ></Input>

                      <Input
                        errors={errors}
                        // required
                        requiredMessage={{
                          pattern: {
                            value: /^(?!www\.)[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                            message: "Please input only domain like athenty.com",
                          },
                        }}
                        register={register}
                        type="text"
                        label="Business Website Domain/URL"
                        id="business_domain"
                        name="business_domain"
                        className="form-control-lg form-control"
                      ></Input>
                      <label style={{

                        textDecoration: 'none', // Prevents underline or other effects
                        pointerEvents: 'none', // Prevents any mouse events (optional)
                      }}
                        className="form-label" htmlFor="name">
                        Business Address

                      </label>
                      <PreviewCard className="mb-3">
                        <Row className="" >
                          <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                            <label

                              disabled className="form-label"
                            >
                              Search Address*
                            </label>
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <input
                                  disabled={watch('cantFind1')}
                                  autoComplete="off"
                                  type="text"
                                  id='searched_address'
                                  value={watch('searched_address') ? watch('searched_address').description : ''}
                                  onInputCapture={(e) => searchAddress(e)}
                                  {...register('searched_address', { required: 'This field is required' })}
                                  // onChange={(e) => type === 'file' && onChange ? onChange(name, e.target.files[0]) : onchange}
                                  placeholder={'Search'}
                                  className={"form-control"}
                                />
                                {errors && errors.searched_address && <p className="invalid">{errors.searched_address.message}</p>}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {!getValues("cantFind1") && predictions1 && searchString1.length >= 3 && (!getValues('searched_address') || typeof getValues('searched_address') !== 'object') && < Row >
                          <ul>
                            {predictions1.map((location) => {
                              return (
                                <li className={"form-control"} onClick={(e) => { setAddress('searched_address', location); clearErrors('searched_address') }}>{location.description}</li>
                              )
                            })}
                          </ul>
                        </Row>
                        }
                        <Row>
                          <Col>
                            <div style={{ paddingLeft: "35px", paddingBottom: "10px" }} className="custom-control-group pt-2">
                              <div className="custom-control custom-checkbox">
                                <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="cantFind1"
                                      checked={watch("cantFind1")}
                                      {...register("cantFind1")}
                                      onChange={(e) => {
                                        setPredictions1(null); setValue('searched_address', null); setValue("cantFind1", !getValues("cantFind1")); e.target.value && clearErrors('searched_address');
                                      }}
                                    />

                                    <label className="custom-control-label" htmlFor="cantFind1">
                                      <div>Can't find your address?</div>
                                    </label>
                                    {errors.cantFind1 && <p className="invalid">{errors.cantFind1.message}</p>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {watch("cantFind1") && (
                          <>
                            <Row className="mb-2">
                              <Input
                                errors={errors}
                                required
                                requiredMessage={{
                                  required: "This field is required",
                                  pattern: {
                                    value: /^.{1,80}$/,
                                    message: "Max 80 characters",
                                  },
                                }}
                                register={register}
                                type="text"
                                label="Business Address Line 1"
                                id="address_1"
                                name="address_1"
                                className=" form-control-lg form-control"
                              ></Input>
                            </Row>
                            <Row className="mb-2">
                              <Input
                                errors={errors}
                                // required
                                requiredMessage={{
                                  pattern: {
                                    value: /^.{1,80}$/,
                                    message: "Max 80 characters",
                                  },
                                }}
                                register={register}
                                type="text"
                                label="Business Address Line 2"
                                id="address_2"
                                name="address_2"
                                className="form-control-lg form-control"
                              ></Input>
                            </Row>
                            <Row className="">
                              <RSelect2
                                onselect={onselect}
                                setValue={setValue}
                                selectedCountry={watch("country")}
                                register={register}
                                requiredMessage="This field is required"
                                errors={errors}
                                options={countryOptions}
                                placeholder="Select country"
                                label="Country"
                                id="country"
                                name="country"
                                className="form-control-lg p-0 border-0 form-control"
                              />
                            </Row>
                            <Row className="mb-2">
                              {watch("country") !== "CA" && watch("country") !== "US" && (
                                <Input
                                  type="text"
                                  label="Province/State"
                                  id="region"
                                  name="region"
                                  className="form-control-lg form-control"
                                  required
                                  requiredMessage={{
                                    required: "This field is required",
                                    pattern: {
                                      value: /^.{1,20}$/,
                                      message: "Max 20 characters",
                                    },
                                  }}
                                  errors={errors}
                                  register={register}
                                />
                              )}
                              {(watch("country") === "CA" || watch("country") === "US") && (
                                <RSelect2
                                  onselect={onselect}
                                  setValue={setValue}
                                  selectedCountry={watch("region")}
                                  register={register}
                                  requiredMessage="This field is required"
                                  errors={errors}
                                  options={regions[watch("country")]}
                                  placeholder="Select country"
                                  label="Provice/State"
                                  id="region"
                                  name="region"
                                  className="form-control-lg p-0 border-0 form-control"
                                />
                              )}
                            </Row>

                            <Row>
                              <Input
                                type="text"
                                label="City "
                                id="city"
                                name="city"
                                className="form-control-lg form-control"
                                required
                                requiredMessage={{
                                  required: "This field is required",
                                  pattern: {
                                    value: /^.{1,20}$/,
                                    message: "Max 20 characters",
                                  },
                                }}
                                errors={errors}
                                register={register}
                              />
                            </Row>
                            <Row>
                              <Input
                                type="text"
                                label="Postal/Zip Code "
                                id="postal"
                                name="postal"
                                className="form-control-lg form-control"
                                required
                                requiredMessage={{
                                  required: "This field is required",
                                  pattern: {
                                    value: /^.{1,20}$/,
                                    message: "Max 20 characters",
                                  },
                                }}
                                errors={errors}
                                register={register}
                              />
                            </Row>
                          </>)}
                      </PreviewCard>

                      <Input
                        type="text"
                        label="Your Percent Ownership"
                        id="percent_ownership"
                        name="percent_ownership"
                        className="form-control-lg form-control"
                        required
                        requiredMessage={{
                          required: "This field is required",
                          pattern: {
                            value: /^(?:100|\d{1,2})$/,
                            message: "Only digits between 0 and 100",
                          },
                        }}
                        errors={errors}
                        register={register}
                      />

                      <div className="form-control-wrap">
                        <div>
                          <RSelect2
                            isMulti
                            onselect={onselect}
                            setValue={setValue}
                            register={register}
                            requiredMessage="This field is required"
                            errors={errors}
                            components={animatedComponents}
                            options={roleOptions}
                            placeholder="Select Title"
                            label="Your Title (select all that apply)"
                            id="business_role"
                            name="business_role"
                            className="form-control-lg p-0 border-0 form-control"
                          />
                        </div>
                      </div>
                      <div className="form-control-wrap">
                        <div>

                          <RSelect2
                            onselect={onselect}
                            setValue={setValue}
                            register={register}
                            requiredMessage="This field is required"
                            errors={errors}
                            options={officerSignTogetherOptions}
                            placeholder="Select"
                            // label="Can this authority sign and bind the business alone or must sign together with another?"
                            label="Can you sign on behalf of and bind this business alone or must sign with another authority?"
                            id={`cosign`}
                            name={`cosign`}
                            className="form-control-lg p-0 border-0 form-control"
                          />
                        </div>
                        {watch(`cosign`) === "1" && (
                          <div className="pt-2">
                            <RSelect2
                              isMulti
                              components={animatedComponents}
                              onselect={onselect}
                              setValue={setValue}
                              register={register}
                              requiredMessage="This field is required"
                              errors={errors}
                              options={signOptions}
                              placeholder="Select"
                              label="Select together with signing authorities"
                              id={`cosign_together`}
                              name={`cosign_together`}
                              className="form-control-lg p-0 border-0 form-control"
                            />

                          </div>
                        )}

                        {watch(`cosign_together`) &&
                          watch(`cosign_together`).find((role) => role === "other") && (
                            <div className="pt-2">
                              <Input
                                type="text"
                                label="Specify Other Signing/Binding Athorities"
                                id={`can_sig_together_other`}
                                name={`cosign_together_other`}
                                className="form-control-lg form-control"
                                required
                                requiredMessage={{
                                  required: "This field is required",
                                  pattern: {
                                    value: /^.{1,20}$/,
                                    message: "Max 20 characters",
                                  },
                                }}
                                errors={errors}
                                register={register}
                              />
                            </div>
                          )}

                        {watch(`cosign`) === "2" && (
                          <div className="pt-2">
                            <Input
                              type="text"
                              label="Specify the other way"
                              id={`cosign_other_way`}
                              name={`cosign_other_way`}
                              className="form-control-lg form-control"
                              required
                              requiredMessage={{
                                required: "This field is required",
                                pattern: {
                                  value: /^.{1,20}$/,
                                  message: "Max 20 characters",
                                },
                              }}
                              errors={errors}
                              register={register}
                            />
                          </div>
                        )}

                      </div>


                      <div className="entry">
                        <h4 id="label_document_scan" className="pt-2 title nk-kycfm-upload-title">
                          Add Other authorities
                        </h4>

                        {formData.officers.map((officer, index) => {
                          return (
                            <div key={index}>
                              <div>
                                <h6 className="mt-3">
                                  <span
                                    value="member_1"
                                    onClick={() => removeOfficer(index)}
                                    className="btn btn-icon delete-button"
                                  >
                                    <em className="ni ni-cross"></em>
                                  </span>
                                  &nbsp; Authority {index + 1}
                                </h6>
                              </div>

                              <div className="form-control-wrap">
                                <RSelect2
                                  // onChange={(e, name) =>
                                  //   onChanegOfficer(e, name, index)
                                  // }
                                  onselect={onselect}
                                  setValue={setValue}
                                  selectedCountry={watch(`officer_role_${index}`)}
                                  register={register}
                                  requiredMessage="This field is required"
                                  errors={errors}
                                  options={officerRoleOptions}
                                  placeholder="Select"
                                  label="Select Role"
                                  id={`officer_role_${index}`}
                                  name={`officer_role_${index}`}
                                  className="form-control-lg p-0 border-0 form-control"
                                />
                              </div>

                              {watch(`officer_role_${index}`) !== "d" && (
                                <div className=" form-control-wrap">
                                  <div>
                                    {watch(`officer_role_${index}`) === "o" && <RSelect2
                                      onselect={onselect}
                                      setValue={setValue}
                                      selectedCountry={watch(`officer_title_${index}`)}
                                      register={register}
                                      requiredMessage="This field is required"
                                      errors={errors}
                                      options={officerTitleOptions}
                                      placeholder="Select"
                                      label="Select Title"
                                      id={`officer_title_${index}`}
                                      name={`officer_title_${index}`}
                                      className="form-control-lg p-0 border-0 form-control"
                                    />}
                                    {watch(`officer_role_${index}`) === "p" && <RSelect2
                                      onselect={onselect}
                                      setValue={setValue}
                                      selectedCountry={watch(`officer_title_${index}`)}
                                      register={register}
                                      requiredMessage="This field is required"
                                      errors={errors}
                                      options={partnerOptions}
                                      placeholder="Select"
                                      label="Select Title"
                                      id={`officer_title_${index}`}
                                      name={`officer_title_${index}`}
                                      className="form-control-lg p-0 border-0 form-control"
                                    />}
                                    {watch(`officer_role_${index}`) === "oth" && <RSelect2
                                      onselect={onselect}
                                      setValue={setValue}
                                      selectedCountry={watch(`officer_title_${index}`)}
                                      register={register}
                                      requiredMessage="This field is required"
                                      errors={errors}
                                      options={otherOptions}
                                      placeholder="Select"
                                      label="Select Title"
                                      id={`officer_title_${index}`}
                                      name={`officer_title_${index}`}
                                      className="form-control-lg p-0 border-0 form-control"
                                    />}
                                    {(watch(`officer_title_${index}`) === "other" || watch(`officer_role_${index}`) === "other") && (
                                      <div className="pt-2">
                                        <Input

                                          type="text"
                                          label={watch(`officer_role_${index}`) === 'other' ? "Specify the Other Role" : "Specify the Other Title"}
                                          // label="Specify the Other Signing/Binding Authority"
                                          id={`officer_title_other_${index}`}
                                          name={`officer_title_other_${index}`}
                                          className="form-control-lg form-control"
                                          required
                                          requiredMessage={{
                                            required: "This field is required",
                                            pattern: {
                                              value: /^.{1,20}$/,
                                              message: "Max 20 characters",
                                            },
                                          }}
                                          errors={errors}
                                          register={register}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              <div className="pt-2">
                                <Input
                                  type="text"
                                  label="First Name"
                                  id={`officer_first_name_${index}`}
                                  name={`officer_first_name_${index}`}
                                  className="form-control-lg form-control"
                                  required
                                  requiredMessage={{
                                    required: "This field is required",
                                    pattern: {
                                      value: /^.{1,80}$/,
                                      message: "Max 80 characters",
                                    },
                                  }}
                                  errors={errors}
                                  register={register}
                                />
                              </div>
                              <div className="pt-2">
                                <Input
                                  type="text"
                                  label="Last Name"
                                  id={`officer_last_name_${index}`}
                                  name={`officer_last_name_${index}`}
                                  className="form-control-lg form-control"
                                  required
                                  requiredMessage={{
                                    required: "This field is required",
                                    pattern: {
                                      value: /^.{1,80}$/,
                                      message: "Max 80 characters",
                                    },
                                  }}
                                  errors={errors}
                                  register={register}
                                />
                              </div>
                              <div className="pt-2">
                                <Input
                                  type="text"
                                  label="Email"
                                  id={`officer_email_${index}`}
                                  name={`officer_email_${index}`}
                                  className="form-control-lg form-control"
                                  required
                                  requiredMessage={{
                                    required: "This field is required",
                                    pattern: {
                                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                      message: "Please enter valid email address",
                                    },
                                  }}
                                  errors={errors}
                                  register={register}
                                />
                              </div>
                              <div className="pt-2 form-control-wrap">
                                <RSelect2
                                  onselect={() => signChange(index)}
                                  setValue={setValue}
                                  selectedCountry={watch(`officer_sign_${index}`)}
                                  register={register}
                                  requiredMessage="This field is required"
                                  errors={errors}
                                  options={officerSignTogetherOptions}
                                  placeholder="Select"
                                  // label="Can this authority sign and bind the business alone or must sign together with another?"
                                  label="Can this authority sign on behalf of and bind this business alone or must sign with another authority?"
                                  id={`officer_sign_${index}`}
                                  name={`officer_sign_${index}`}
                                  className="form-control-lg p-0 border-0 form-control"
                                />
                                {watch(`officer_sign_${index}`) === "1" && (
                                  <div className="pt-2">
                                    <RSelect2
                                      isMulti
                                      components={animatedComponents}
                                      onselect={onselect}
                                      setValue={setValue}
                                      selectedCountry={watch(`officer_sign_together_${index}`)}
                                      register={register}
                                      requiredMessage="This field is required"
                                      errors={errors}
                                      options={signOptions}
                                      placeholder="Select"
                                      label="Select together with signing authorities"
                                      id={`officer_sign_together_${index}`}
                                      name={`officer_sign_together_${index}`}
                                      className="form-control-lg p-0 border-0 form-control"
                                    />
                                  </div>
                                )}

                                {watch(`officer_sign_together_${index}`) &&
                                  watch(`officer_sign_together_${index}`).find((role) => role === "other") && (
                                    <div className="pt-2">
                                      <Input
                                        type="text"
                                        label="Specify Other Signing/Binding Athorities"
                                        id={`officer_sign_together_other_value${index}`}
                                        name={`officer_sign_together_other_value${index}`}
                                        className="form-control-lg form-control"
                                        required
                                        requiredMessage={{
                                          required: "This field is required",
                                          pattern: {
                                            value: /^.{1,20}$/,
                                            message: "Max 20 characters",
                                          },
                                        }}
                                        errors={errors}
                                        register={register}
                                      />
                                    </div>
                                  )}

                                {watch(`officer_sign_${index}`) === "2" && (
                                  <div className="pt-2">
                                    <Input
                                      type="text"
                                      label="Specify the other way"
                                      id={`officer_sign_other_way_${index}`}
                                      name={`officer_sign_other_way_${index}`}
                                      className="form-control-lg form-control"
                                      required
                                      requiredMessage={{
                                        required: "This field is required",
                                        pattern: {
                                          value: /^.{1,20}$/,
                                          message: "Max 20 characters",
                                        },
                                      }}
                                      errors={errors}
                                      register={register}
                                    />
                                  </div>
                                )}
                              </div>
                              {/* <InputRadio 
                              label= 'Alone'
                              id = 'alone'
                              name = 'alone'
                              onChange
                              /> */}
                            </div>
                          );
                        })}

                        <Button
                          color="primary"
                          size="block"
                          className="mt-3"
                          outline=""
                          type="button"
                          onClick={AddOfficer}
                        >
                          + Add Authority
                        </Button>
                        <div className="mt-5"></div>
                      </div>
                    </div>
                  )}
                  <div className="mt-3 nk-kycfm-note">
                    <em className=" icon ni ni-info-fill"></em>
                    <p>You cannot edit your details once submitted.</p>
                  </div>
                  {/* <div className="actions clearfix">
                    <ul>
                      <li>
                        <button type="submit" style={{float:'right'}} className="btn btn-primary">Next</button>
                      </li>
                    </ul>
                  </div> */}

                  <div className="bg-primary" style={{ position: "fixed", bottom: "0px" }}>
                    <div className="bg-white col-12" style={{ position: "fixed", bottom: "0px", right: "0px" }}>
                      <div style={{ margin: "10px 5px 10px 0px", float: "right" }} className="p-1">
                        {" "}
                        <Button color="primary" size="" className={submitButtonLoading && "disabled"} outline="">
                          {submitButtonLoading && <Spinner size="sm" />} Continue
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Block>
          </div>
          <ToastContainer />
        </Content>
      )}
    </React.Fragment>
  );
};

export default Business;
