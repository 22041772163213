import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ReverseVerifyRegister from "../pages/auth/ReverseVerifyRegister.js";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Success from "../pages/auth/Success";
import Organization from "../pages/customer/admin/organization/Organization";
import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
//new page imports
import CustomerDashboard from "../pages/customer/Index";
import ClientList from "../pages/customer/menu/client-list/ClientList";
// import Verify from "../pages/customer/solutions/verify/Verify";
import Users from "../pages/customer/admin/access/users/Users";
import EditUsers from "../pages/customer/admin/access/users/EditUser";
import ApiTokens from "../pages/customer/admin/access/apiToken/ApiToken";
import Subscriptions from "../pages/customer/admin/billing/subscription/Subscription";
import InvoiceHistory from "../pages/customer/admin/billing/invoiceHistory/InvoiceHistory";
import PaymentHistory from "../pages/customer/admin/billing/paymentHistory/PaymentHistory";
import Transactions from "../pages/customer/admin/billing/transactions/Transactions";
import PaymentMethods from "../pages/customer/admin/billing/paymentMethods/PaymentMethods";
//CCR Forms
import VerifyWelcome from "../pages/ccr/verify/VerifyWelcome";
import VerifyForm from "../pages/ccr/verify/VerifyForm";
import Complete from "../pages/ccr/verify/Complete";
import Decision from "../pages/ccr/verify/Desicion";
import OrganizationGeneral from "../../src/pages/customer/admin/organization/general/OrganizationGeneral";
import OrganizationNotification from "../../src/pages/customer/admin/organization/notification/OrganizationNotification";
import OrganizationSecurity from "../../src/pages/customer/admin/organization/security/OrganizationSecurity";
import OrganizationSetting from "../../src/pages/customer/admin/organization/setting/OrganizationSetting";
import CustomerProfilePersonal from "../../src/pages/customer/admin/access/customerProfile/personal/CustomerProfilePersonal";
import ProfilePersonal from "../../src/pages/customer/admin/access/customerProfile/CustomerProfile";
import AddSubscription from "../../src/pages/customer/admin/billing/subscription/addSubscription/AddSubscription";
import InvoiceDetail from "../../src/pages/customer/admin/billing/invoiceHistory/invoiceDetail/InvoiceDetail";
import PaymentMethodAdd from "../../src/pages/customer/admin/billing/paymentMethods/paymentMethodAdd/PaymentMethodAdd";
import Test from "../pages/customer/test/Test";
import Error401Classic from "../pages/error/401-classic";
import SignupVerify from "../pages/auth/RegisterVerify";

import ClientDetail from "../pages/customer/menu/client-list/ClientListDetail/ClientDetail";
import PaymentMethodEdit from "../pages/customer/admin/billing/paymentMethods/paymentMethodEdit/PaymentMethodEdit";
import CustomerProfile from "../../src/pages/customer/admin/access/customerProfile/CustomerProfile";
import DownloadPDF from "../components/pdf-document/DownloadPDF";
import InvoicePrint from "../pages/customer/admin/billing/invoiceHistory/invoicePrint/InvoicePrint";
import Ocr from "../pages/customer/test/Ocr";
// import Stripe from "../pages/customer/test/Stripe";
import Facial from "../pages/customer/test/Facial";
import Sms from "../pages/customer/test/sms";
import NotMobile from "../pages/ccr/verify/NotMobile";
// import { isMobile } from "react-device-detect";
import { isAuthenticated } from "../utils/Utils";
import Tickets from "../pages/customer/support/Tickets";
import TicketDetails from "../pages/customer/support/TicketDetails/TicketDetails";
import Pdf from "../pages/customer/test/Pdf";
// import LandingPage from "../pages/share-pdf/LandingPage";
import CodeVerification from "../pages/share-pdf/CodeVerification";
import ClientApproval from "../pages/share-pdf/ClientApproval";
// import Share from "../pages/customer/test/Share";
import ThirdPartyPdf from "../pages/share-pdf/ThirdPartyPdf";

//notifications
import Notifications from "../pages/customer/notifications/Notifications";
// import Error503Modern from "../pages/error/503-modern";
import Error503Classic from "../pages/error/503-classic";
import MattertList from "../pages/customer/menu/matter-list/MattertList";
import MatterClientList from "../pages/customer/menu/matter-list/MatterClientList/MatterClientList";
import Camera from "../pages/test/Camera";
import LocationMobile from "../pages/customer/test/Location";
import ResetPassword from "../pages/auth/resetPassword";
import CreditOcr from "../pages/customer/test/Ocr copy.js";
import Docscan from "../pages/customer/test/Docscan.js";
import ResetPasswordAgent from "../pages/auth/resetPasswordAgent.js";
import ResetPasswordVerify from "../pages/auth/ResetPasswordVerify.js";
import VoiceRec from "../pages/customer/test/VoiceRec.js";
// import Share from "../pages/customer/solutions/share/Share.js";
// import ShareAdd from "../pages/customer/solutions/share/add/ShareAdd.js";
// import ShareDetail from "../pages/customer/solutions/share/shareDetail/shareDetail.js";
import SmartShare from "../pages/ccr/share/SmartShare.js";
// import Kyc from "../pages/customer/solutions/kyc/Kyc.js";
// import KycAdd from "../pages/customer/solutions/kyc/add/KycAdd.js";
import KycWelcome from "../pages/ccr/kyc/KycWelcome.js";
import KycForm from "../pages/ccr/kyc/Form.js";
// import VerifyDetail from "../pages/customer/solutions/verify/detail/VerifyDetail.js";
// import VerifyAdd from "../pages/customer/solutions/verify/add/VerifyAdd.js";
// import KycDetail from "../pages/customer/solutions/kyc/KycDetail/KycDetail.js";
import CameraNew from "../pages/customer/test/Camera.js";
import ThirdPartyList from "../pages/customer/menu/third-party-list/ThirdPartyList.js";
import AddThirdParty from "../pages/customer/menu/third-party-list/AddThirdParty.js";
import SolutionList from "../pages/customer/solutions/SolutionList.js";
import SolotionDetails from "../pages/customer/solutions/SolotionDetails/SolotionDetails.js";
import SolutionAddWizard from "../pages/customer/solutions/add/solotionAddWizard/SolutionAddWizard.js";
import SolutionAdd from "../pages/customer/solutions/add/SolutionAdd.js";
import ClientAdd from "../pages/customer/menu/client-list/ClientAdd/ClientAdd.js";
import LogOut from "../pages/customer/LogOut.js";

// check cookie / security --------------------------------------------

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = async () => {
    const userToken = await isAuthenticated();
    console.log(location)
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      return navigate("/", { state: { from: location } });
    }
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  return children;
};
// --------------------------------------------------------------------

const Pages = (props) => {
  // console.log(props);
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    document.body.classList.add("has-aside");
    document.body.classList.remove("npc-apps", "apps-only");
  }, []);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isTouchScreen = "ontouchstart" in window || navigator.maxTouchPoints > 0;
    const maxMobileWidth = 768; // You can adjust this value

    // Check if user agent is for mobile
    if (/android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)) {
      return true;
    }

    // Additional check for screen size and touch capability
    return isTouchScreen && window.innerWidth <= maxMobileWidth;
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<Login state={{ from: location }} />} />
        {/* <Route path="customer" element={<Layout />}> */}
        <Route
          path="customer"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route index element={<CustomerDashboard />} />
          <Route path="logout" element={<LogOut />} />
          <Route path="tickets">
            <Route index element={<Tickets />} />
            <Route path=":ticket_id" element={<TicketDetails />} />
          </Route>
          <Route path="notifications" element={<Notifications />} />
          <Route path="camera" element={<CameraNew />} />
          <Route path="index" element={<CustomerDashboard />} />
          <Route path="test" element={<Test />} />
          <Route path="ocr" element={<Ocr />} />
          <Route path="voice" element={<VoiceRec />} />
          <Route path="credit" element={<CreditOcr />} />
          <Route path="pdf" element={<Pdf />} />
          <Route path="location" element={<LocationMobile />} />
          <Route path="docscan" element={<Docscan />} />
          {/* <Route path="stripe" element={<Stripe />} /> */}
          <Route path="facial" element={<Facial />} />
          <Route path="sms" element={<Sms />} />
          <Route path="menu">
            <Route path="matter-list">
              <Route index element={<MattertList />} />
              <Route path=":matter_id">
                <Route path="client-list" element={<MatterClientList />} />
              </Route>
            </Route>
            <Route path="client-list">
              <Route index element={<ClientList />} />
              <Route path=":request_id" element={<ClientDetail />} />
              <Route path="add" element={<ClientAdd />} />
            </Route>
            <Route path="third-party-list">
              <Route index element={<ThirdPartyList />} />
              <Route path="add" element={<AddThirdParty />} />
            </Route>
          </Route>
          <Route path="solutions">
            {/* <Route path="verify" element={<Verify />} />
            <Route path="verify/:id" element={<VerifyDetail />} />
            <Route path="verify/add" element={<VerifyAdd />}> */}
            {/* <Route index element={<VerifyAddNew />} /> */}
            {/* <Route path="existing" element={<VerifyAddExisting />} /> */}
            {/* </Route> */}
            {/* <Route path="share" element={<Share />} />
            <Route path="share/add" element={<ShareAdd />} />
            <Route path="share/:id" element={<ShareDetail />} /> */}
            <Route path=":type" element={<SolutionList />} />
            <Route path=":type/add" element={<SolutionAdd />} />
            <Route path=":type/:id" element={<SolotionDetails />} />
          </Route>
          <Route path="admin">
            <Route path="organization" element={<Organization />}>
              <Route index element={<OrganizationGeneral />} />
              <Route path="notification" element={<OrganizationNotification />} />
              <Route path="security" element={<OrganizationSecurity />} />
              <Route path="setting" element={<OrganizationSetting />} />
            </Route>
            <Route path="access">
              <Route path="users">
                <Route index element={<Users />} />
                <Route path="add" element={<EditUsers mode="add" />} />
                <Route path="edit/:id" element={<EditUsers mode="edit" />} />
              </Route>
              <Route path="api-token" element={<ApiTokens />} />
              <Route path="profile" element={<CustomerProfile />}>
                <Route index element={<CustomerProfilePersonal />} />
                <Route path="personal" element={<ProfilePersonal />} />
              </Route>
            </Route>
            <Route path="billing">
              <Route path="subscription" element={<Subscriptions />} />
              <Route path="subscription/add" element={<AddSubscription />} />
              <Route path="invoices">
                <Route index element={<InvoiceHistory />} />
                <Route path="invoice-detail/:invoiceId" element={<InvoiceDetail />} />
              </Route>
              <Route path="payment-history" element={<PaymentHistory />} />
              <Route path="transactions" element={<Transactions />} />
              <Route path="payment-methods">
                <Route index element={<PaymentMethods />} />
                <Route path="add" element={<PaymentMethodAdd />} />
                <Route path="edit/:id" element={<PaymentMethodEdit />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
          <Route path="auth-success" element={<Success />}></Route>
          <Route path="auth-reset" element={<ForgotPassword />}></Route>
          <Route path="auth-register" element={<Register />}></Route>
          <Route path="reset-password/:token" element={<ResetPasswordAgent />}></Route>
          <Route path="reset-password-verify/:token" element={<ResetPasswordVerify />}></Route>
          <Route path="auth-reverse-verify-register" element={<ReverseVerifyRegister />}></Route>
          <Route path="auth-register-verify" element={<SignupVerify />}></Route>
          <Route path="auth-login" element={<Login state={{ from: location }} />}></Route>
          <Route path="verify" element={<ResetPassword />}></Route>
          <Route path="ccr">
            {isMobileDevice() && <Route path="share" element={<SmartShare />}></Route>}
            {isMobileDevice() && <Route path="kyc" element={<KycWelcome />}></Route>}
            {isMobileDevice() && <Route path="kyc/form" element={<KycForm />}></Route>}
            {isMobileDevice() && <Route path="kyc/decision" element={<Decision />}></Route>}
            {isMobileDevice() && <Route path="verify" element={<VerifyWelcome />}></Route>}
            {isMobileDevice() && <Route path="verify/form" element={<VerifyForm />}></Route>}
            {isMobileDevice() && <Route path="verify/complete" element={<Complete />}></Route>}
            {isMobileDevice() && <Route path="verify/decision" element={<Decision />}></Route>}
            {!isMobileDevice() && <Route path="*" element={<NotMobile />}></Route>}
          </Route>

          <Route
            path="download-pdf/:id"
            element={
              <ProtectedRoute>
                <DownloadPDF />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="/share/svr/:smartVerifyUuid/third-party/report" element={<ThirdPartyPdf />}></Route>

          <Route path="/share/svr/:smartVerifyUuid/" element={<ClientApproval />}></Route>
          <Route path="/share/svr/:smartVerifyUuid/third-party" element={<CodeVerification />}></Route>

          <Route path="errors">
            <Route path="401-classic" element={<Error401Classic />}></Route>
            <Route path="404-modern" element={<Error404Modern />}></Route>
            <Route path="404-classic" element={<Error404Classic />}></Route>
            <Route path="503-modern" element={<Error503Classic />}></Route>
            <Route path="504-modern" element={<Error504Modern />}></Route>
            <Route path="504-classic" element={<Error504Classic />}></Route>
          </Route>
        </Route>
        <Route path="invoice-print/9" element={<InvoicePrint />}></Route>
        <Route path="camera" element={<Camera />}></Route>

        <Route path="*" element={<Error404Modern />}></Route>
        <Route path="*" element={<LayoutNoSidebar />}>
          <Route index element={<Login state={{ from: location }} />} />
        </Route>
      </Routes>
    </>
  );
};
export default Pages;
