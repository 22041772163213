import { Icon, Loading } from "../../../../../../components/Component";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { useEffect, useState } from "react";
import { apiRequest } from "../../../../../../utils/Api";
import RSelect2 from "../../../../../../components/select/ReactSelect2";





const SolutionWizardSelect = (props) => {
    // const [agentsOptions, setAgentsOptions] = useState()
    // const [originalAgentOtions, setOriginalAgentOtions] = useState()
    // const [thirdPartyOptions, setThirdPartyOptions] = useState()
    // const [originalThirdpartytOtions, setOriginalThirdpartytOtions] = useState()
    const [loading, setLoading] = useState(true)

    const { handleSubmit, watch, clearErrors, register, errors, setError, setValue, getValues, } = props
    const [isNewMatter, setIsNewMatter] = useState()
    const [isClientSelected, setIsClientSelected] = useState(false)

    const onselect = (name, value) => {
        console.log(name)
        console.log(value)
        if (name === "client") {
            const result = props.data.client.find(obj => obj.value === value)
            console.log(result)
            setValue('client', result)
            setLoading(true)
            getAllDetails(result.id)
            // setValue('client_name_last', result.name_last)
            // setValue('client_id', result.external_id)
            // setValue('client_email', result.email)
            // setValue('client_phone', result.phone)

            // setValue("client", isNaN(value) ? value : options.filter(obj => obj.value === value)[0].label);
        }
        if (name === "matter") {
            console.log(isNaN(value))
            if (isNaN(value)) {
                setIsNewMatter(true)
                setValue('matter', {
                    label: value,
                    value: value,
                    reason: getValues('matter_reason')
                })
                setValue('matter_reason', null)

            } else {
                setValue('matter_reason', null)
                setIsNewMatter(true)
                let selectedMatter = props.data.matter.find(obj => obj.value === value)
                console.log(selectedMatter)
                setValue('matter', selectedMatter)
            }
            // setValue("matter", isNaN(value) ? value : props.data.matter.find(obj => obj.value === value));
            // const isExisting = !isNaN(value);
            // if (!isExisting) {

            // }
            // else setIsNewMatter(false)
            console.log(getValues('matter'))
        }
        if (name === "third-party") {

            const result = props.data.thirdparty.filter(obj => value.includes(obj.value))
            if (getValues('third-party') && getValues('third-party').length > 5) {
                setError('third-party', { type: "manual", message: "Maximum 5" })

            } else {
                clearErrors('third-party')
            }
            setValue('third-party', result)
        }
        console.log(errors)
        if (name === "agent") {

            const result = props.data.agent.find(obj => obj.value === value)
            setValue('agent', result)

        }
    };



    useEffect(() => {
        setLoading(true)
        if (getValues('client')) setIsClientSelected(true)

        getClientDitails()
    }, [])



    const getClientDitails = async () => {
        await apiRequest(`/customer/a/solution/smart/data-client/`, "get").then(res => {
            props.setData(prevState => ({
                ...prevState,
                client: res.data.client,
            }));

            setLoading(false)

        })
    }

    const getAllDetails = async (id) => {
        setLoading(true)
        await apiRequest(`/customer/a/solution/smart/data-all/${id}`, "get").then(res => {

            props.setData(prevState => ({
                ...prevState,
                agent: res.data.agent,
                matter: res.data.matter,
                thirdparty: res.data.thirdparty
            }));
            setIsClientSelected(true)

            setLoading(false)

        })
    }

    const onFormSubmit = async () => {
        console.log(getValues())
        // console.log(errors)
        if (getValues('third-party') && getValues('third-party').length > 5) {
            setError('third-party', { type: "manual", message: "Maximum 5" })
            return
        }
        // return
        setValue('matter', {
            value: getValues('matter').value,
            reason: getValues('matter_reason') || getValues('matter').reason,
            label: getValues('matter').label
        })
        // return

        props.next()
    }


    return (
        <>
            < form className="content clearfix" onSubmit={handleSubmit(onFormSubmit)}>
                {loading ? <Loading />
                    :
                    <div className="form-control-wrap">
                        <h4>Client And Recipients</h4>
                        <p onClick={() => console.log(props.agentList)}>
                            Please Select Client And The parties authorized to receive the report:
                        </p>

                        <Container>
                            {loading && !props.data ? <Loading />
                                :
                                // <PreviewCard>
                                //     <div className="dual-listbox">

                                //         <input className="dual-listbox__search" placeholder="Search" onChange={(e) => setFilterText(e.target.value)} />

                                //         <DualListBox
                                //             options={options}
                                //             selected={selected}
                                //             icons={buttonIcon}
                                //             onChange={onListChange}
                                //             showHeaderLabels={true}
                                //         ></DualListBox>
                                //     </div>
                                // </PreviewCard>
                                <Container >
                                    <Row >
                                        <Col xl="6" sm="6" xs="12" className="mb-2">
                                            <Row>
                                                <RSelect2
                                                    onselect={onselect}
                                                    setValue={setValue}
                                                    clearErrors={clearErrors}
                                                    // selectedCountry={watch("region")}
                                                    label="Client Name (Client ID)"
                                                    register={register}
                                                    requiredMessage="This field is required"
                                                    id="client"
                                                    name="client"
                                                    errors={errors}
                                                    options={props.data.client ? props.data.client : []}
                                                    placeholder="Type client name to search"
                                                    className="form-control-lg p-0 border-0 form-control"
                                                    isSearchable={true}
                                                    defaultValue={() => getValues('client')}
                                                    // icon="help"
                                                    iconStyle={{ fontSize: '20px' }}
                                                />
                                            </Row>

                                            {/* {watch('client') && <Row>

                                                <>
                                                    <div className="pt-2form-label-group">
                                                        <label className="form-label" htmlFor="default-01">
                                                            Client Information

                                                        </label>
                                                    </div>
                                                    <Card style={{ width: '93%' }} className="pt-2 pb-2 card-preview mx-auto" >
                                                        <div className="team">
                                                            <div className="user-card user-card-s2">
                                                                <UserAvatar theme={getValues('client').avatarBg} className="md" text={getInitials(getValues('client').name)} image={getValues('client').image}>
                                                                    <div className="status dot dot-lg dot-success"></div>
                                                                </UserAvatar>
                                                                <div className="user-info">
                                                                    <h6>{getValues('client').label}</h6>
                                                                    <span className="sub-text">{getValues('client').email}</span>
                                                                </div>
                                                            </div>
                                                            <div className="team-details">
                                                                <p>Phone Number: {getValues('client').phone}</p>
                                                            </div>
                                                            <div className="team-details">
                                                                <p>Client ID: {getValues('client').external_id}</p>
                                                            </div>
                                                        </div >
                                                    </Card>
                                                </>

                                            </Row>} */}

                                        </Col>
                                        <Col xl="6" sm="6" xs="12" className="mb-2">

                                            <Row >
                                                <RSelect2
                                                    disabled={!isClientSelected}
                                                    onselect={onselect}
                                                    setValue={setValue}
                                                    clearErrors={clearErrors}
                                                    // selectedCountry={watch("region")}
                                                    label="Primary Recipient"
                                                    register={register}
                                                    requiredMessage="This field is required"
                                                    id="agent"
                                                    name="agent"
                                                    errors={errors}
                                                    options={props.data.agent ? props.data.agent : []}
                                                    placeholder={isClientSelected ? "Type agent name to search" : "Please select client first"}
                                                    className="form-control-lg p-0 border-0 form-control"
                                                    isSearchable={true}
                                                    defaultValue={getValues('agent')}
                                                />
                                            </Row>

                                        </Col>



                                    </Row>
                                    <Row>


                                        <Col xl="6" sm="6" xs="12" className="mb-2">
                                            <Row>
                                                <RSelect2
                                                    disabled={!isClientSelected}
                                                    onselect={onselect}
                                                    setValue={setValue}
                                                    clearErrors={clearErrors}
                                                    // selectedCountry={watch("region")}
                                                    label="Matter ID"
                                                    register={register}
                                                    requiredMessage="This field is required"
                                                    id="matter"
                                                    name="matter"
                                                    errors={errors}
                                                    createable={true}
                                                    options={props.data.matter ? props.data.matter : []}
                                                    placeholder={isClientSelected ? "Select or type new matter" : 'Please slelect client first'}
                                                    className="form-control-lg p-0 border-0 form-control"
                                                    defaultValue={() => getValues('matter')}
                                                />
                                                <span className="form-note">e.g: 1234-0001</span>
                                            </Row>
                                            {watch('matter') && typeof watch('matter').value === 'string' && <Row>

                                                <div className="form-label-group">
                                                    <label className="form-label" htmlFor="default-01">
                                                        Matter Description or Reason<span className="text-danger">*</span>
                                                    </label>
                                                </div>
                                                <div className="form-control-wrap">
                                                    <textarea
                                                        id="matter_reason"
                                                        name="matter_reason"
                                                        type="text"
                                                        placeholder="e.g: purchase of property of 1233 example road."
                                                        className="  form-control form-control-lg "
                                                        {...register("matter_reason", {
                                                            required: "This field is required",
                                                            pattern: {
                                                                value: /^.{1,128}$/,
                                                                message: "Max 128 characters",
                                                            },
                                                        })}

                                                    >{watch('matter').value}</textarea>
                                                    {errors.matter_reason && <p className="invalid">{errors.matter_reason.message}</p>}
                                                </div>

                                            </Row>}



                                            {/* {clientList.filter(obj => obj.uuid === props.props.watch('client')).map(client => {
                                        return (
                                            <Card className="card-bordered p-2">
                                                <div>Client Name: {client.name}</div>
                                                <div>Client Email: {client.email}</div>
                                                <div>Client Phone: {client.phone}</div>
                                                <div>Client ID: {client.external_id}</div>

                                            </Card>
                                        )
                                    })} */}
                                            {/* {options.filter(obj => obj.value === props.props.watch('client')))} */}

                                        </Col>


                                        <Col xl="6" sm="6" xs="12" className="mb-2">
                                            <Row>
                                                <RSelect2
                                                    disabled={!isClientSelected}
                                                    isMulti
                                                    onselect={onselect}
                                                    setValue={setValue}
                                                    clearErrors={clearErrors}
                                                    // selectedCountry={watch("region")}
                                                    label="Third-party Recipient(s)"
                                                    register={register}
                                                    // requiredMessage="This field is required"
                                                    id="third-party"
                                                    name="third-party"
                                                    errors={errors}
                                                    // createable={true}
                                                    options={props.data.thirdparty ? props.data.thirdparty : []}
                                                    placeholder={isClientSelected ? "Type third-party name to search" : 'Please select client first'}
                                                    className="form-control-lg p-0 border-0 form-control"
                                                    defaultValue={() => getValues('third-party')}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Alert className="alert-icon" color="primary">
                                        <Icon name="alert-circle" />

                                        <ul>
                                            <li>

                                                <strong>Primary Recipient:</strong> Responsible member of your organization for this verification.
                                            </li>
                                            <li>

                                                <strong>Third-party Recipient:</strong> Outside parties that will have access to the verification report.
                                            </li>
                                        </ul>
                                    </Alert>
                                </Container >
                            }
                            <div className="actions clearfix">
                                <ul>
                                    <li>
                                        <Button color="primary" type='submit' >Next</Button>
                                    </li>
                                    {/* <li>
                                        <Button color="primary" onClick={props.prev} >Back</Button>
                                    </li> */}
                                </ul>
                            </div>
                        </Container >
                    </div >
                }
            </form>
        </>

    );
}

export default SolutionWizardSelect